<template>
    <div class="infobar">

        <g-caption size="4">ID: {{ profile.lead_id }}</g-caption>

        <fieldset v-if="profile.account_level" class="level">
            <legend>Level:</legend>
            <g-caption size="2"  weight="bold" v-bind:class="profile.account_level.toLowerCase()">{{ profile.account_level }}</g-caption>
        </fieldset>

        <fieldset class="balance">
            <legend>Balance:</legend>
            <g-flex align="center" gap="4">
                <g-symbol name="tether" width="18" height="18"/>
                {{ profile.balance }}
            </g-flex>
        </fieldset>

        <fieldset class="email-confirm">
            <legend>Email confirmed:</legend>
            <div v-bind:class="phoneConfirmed">{{ $t(`base.${emailConfirmed}`) }}</div>
        </fieldset>

        <fieldset class="phone-confirm">
            <legend>Phone confirmed:</legend>
            <div v-bind:class="phoneConfirmed">{{ $t(`base.${phoneConfirmed}`) }}</div>
        </fieldset>

        <fieldset class="date">
            <legend>Registration at:</legend>
            {{ profile.created_at }}
        </fieldset>
    </div>
</template>

<script>

import { computed } from 'vue';
import { useProfileActions } from '@profile';

const getProfileResource = useProfileActions('getProfileResource');

export default {
    name: 'Infobar',

    setup() {
        const profile = getProfileResource();

        const emailConfirmed = computed(() => {
            return profile.email_confirmed.toLowerCase();
        });

        const phoneConfirmed = computed(() => {
            return profile.phone_confirmed.toLowerCase();
        });

        return {
            profile,
            emailConfirmed,
            phoneConfirmed,
        };
    }
};
</script>

<style lang="scss" scoped>
.infobar {
    padding: 20px;
    border-radius: 9px;
    border: 1px solid var(--separator-color, $separator-color);
}

fieldset {
    margin: 20px 0;
    color: var(--title-text-color, $title-text-color);
    font-family: var(--font-title, $font-title);
    fill: var(--main-text-color, $main-text-color);
    font-size: 1.1rem;

    legend {
        font-size: 0.9rem;
        text-transform: capitalize;
        font-family: var(--font-primary, $font-primary);
        color: var(--main-text-color, $main-text-color);
    }

    &.level {
        grid-area: lvl;
    }

    &.balance {
        grid-area: bln;
    }

    &.email-confirm {
        grid-area: ecm;
    }

    &.phone-confirm {
        grid-area: pcm;
    }

    &.date {
        grid-area: dat;
    }
}

.g-caption {
    padding: 0 !important;
}

.silver {
    color: silver;
}

.gold {
    color: gold;
}

.yes {
    color: var(--success-hover, $success-hover);
}

.no {
    color: var(--danger-hover, $danger-hover);
}

@media (min-width: 345px) AND (max-width: 624px) {
    fieldset {
        margin: 0;
    }

    .infobar {
        gap: 20px;
        display: grid;
        align-items: center;
    }

    @media (min-width: 465px) {
        .infobar {
            grid-template-areas:
                'lvl bln ecm'
                'lvl dat pcm';
        }
    }

    @media (max-width: 464px) {
        .infobar {
            grid-template-areas:
                'lvl bln'
                'ecm pcm'
                'dat dat';
        }
    }
}
</style>
