
import { useOrderBookResources } from '@order-book';

export default function(reload = false) {

    const order = useOrderBookResources('Order');

    // if (reload || (!order.isLoaded && !order.isLoading)) {
	//
    //     order.load({
    //         market: ['crypto'],
    //         base: ['BTC'],
    //         counter: ['USDT'],
    //     });
    // }

    return order;
}