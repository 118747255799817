<template>
    <div class="receive">
        <g-copy v-bind:text="address" class="address"/>
        <img v-bind:src="qrCodeLink" v-bind:alt="address">
    </div>
</template>

<script>

import { computed } from 'vue';

export default {
    name: 'Receive',
    components: {},
    props: ['address'],

    setup(props) {
        const qrCodeLink = computed(() => {
            const qrCodeGeneratorService = 'https://chart.googleapis.com/chart?cht=qr&chs=484x484&chl=';
            return qrCodeGeneratorService.concat(props.address);
        });

        return {
            qrCodeLink
        };
    }
};
</script>

<style scoped lang="scss">
.receive {
    overflow: hidden;
}

.address {
    z-index: 2;
    margin-bottom: 30px;
}

img {
    transform: scale(1.28);
    mix-blend-mode: darken;
}
</style>
