<template>
    <div class="g-caption" :class="`h${size}`">
        <slot/>
    </div>
</template>

<script>

export default {
    name: 'GCaption',
    props: {
        size: {
            default: '1'
        },
        weight: {
            default: '400'
        },
        align: {
            default: 'left'
        }
    },
};
</script>

<style lang="scss" scoped>
.g-caption {
    line-height: 130%;
    font-weight: v-bind(weight);
    text-align: v-bind(align);
    font-family: var(--font-primary, $font-primary);
}

.h1 {
    font-size: var(--h1, $h1);
    padding: 15px 0;
}

.h2 {
    font-size: var(--h2, $h2);
    padding: 12px 0;
}

.h3 {
    font-size: var(--h3, $h3);
    padding: 10px 0;
}

.h4 {
    font-size: var(--h4, $h4);
    padding: 5px 0;
}

.h5 {
    font-size: var(--h5, $h5);
    padding: 5px 0;
}

.bold {
    font-weight: 700;
}

.uppercase {
    text-transform: uppercase;
}
</style>