
import { watch } from 'vue';
import { useChartResources } from '@chart';

export default function (ticker, resolution, onRealtimeCallback, subscribeUID, onResetCacheNeededCallback) {
    const bar = useChartResources('Bar');

    bar.subscribe = {
        resolution,
        listener: onRealtimeCallback,
    };

    watch(bar.state[bar.index], candle => {
        onRealtimeCallback(candle);
    });
}