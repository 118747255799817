import { useShipResources } from '@/Ship';

const { BaseResource } = useShipResources();

export default class Country extends BaseResource {

	static #instance = null;

	endpoint = 'countries';

	section = 'Profile';
	container = 'Profile';

	static getInstance() {
		if (!Country.#instance) {
			Country.#instance = new Country();
		}
		return Country.#instance;
	}
}
