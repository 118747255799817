
import { usePositionResources, usePositionActions } from '@position';

const plCalculate = usePositionActions('plCalculate');

export default function(reload = false) {

    const positions = usePositionResources('Position');

    if (reload || (!positions.isLoaded && !positions.isLoading)) {
        positions.setIncludes(['pair']);
        positions.setFilters({
            status: ['open']
        });

        positions.onload.push(() => {
            positions.state.forEach(plCalculate);
        });
		
        positions.load();
    }

    return positions;
}