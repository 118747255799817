
import { useDepositResources } from '@deposit';

export default function(reload = false) {

	const deposit = useDepositResources('Deposit');

	if (reload || (!deposit.isLoaded && !deposit.isLoading)) {
		//deposit.setIncludes([]);
		deposit.sort('created_at','desc');
		deposit.load();
	}

	return deposit;
}