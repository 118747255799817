<template>
    <div class="g-preloader-bar loader"></div>
</template>

<script>
export default {
    name: "gPreloaderRefresh"
}
</script>

<style lang="scss" scoped>
.loader {
    top: 50%;
    left: 50%;
    width: 48px;
    height: 48px;
    position: absolute;
    border: 3px solid;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    animation: 1s rotate linear infinite;
    border-color: var(--primary, $primary) #0000 #fff #0000;
}

.loader::before,
.loader::after {
    content: '';
    top: 0;
    left: 0;
    position: absolute;
    border: 10px solid transparent;
    border-bottom-color: #ffffff;
    transform: translate(-10px, 19px) rotate(-35deg);
}

.loader::after {
    border-color: var(--primary, $primary) #0000 #0000 #0000;
    transform: translate(32px, 3px) rotate(-35deg);
}

@keyframes rotate {
    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}


</style>