<template>
    <div class="wallet wallet-wrapper">

        <!-- CARD -->
        <wallet-card v-bind:network="network"
                     v-bind:symbol="currency"
                     v-bind:balance="balance"
                     v-bind:class="{disabled: !wallet}"/>
        <!-- /CARD -->

        <template v-if="wallet">
            <div class="address">
                <g-caption size="4" weight="700">{{ $t('wallet.address') }}</g-caption>
                <g-copy v-bind:text="wallet.address"/>
            </div>

            <replenishing v-bind:wallet="wallet"/>

            <!-- ACTIONS -->
            <div class="actions">
                <g-button class="withdraw-btn" @click="openSendPopUp">
                    <g-symbol name="card-send" width="22" height="22"/>
                </g-button>
                <g-button class="receive-btn"
                          @click="PopUp.open(require('@wallet/components/parts/Receive')).setCaption($t('wallet.receive')).setPayload({ address: wallet.address })">
                    <g-symbol name="card-receive" width="22" height="22"/>
                </g-button>
                <!--
                <g-button class="remove-btn"
                          @click="PopUp.open(require('@wallet/components/parts/RemoveWallet')).setCaption($t('popupTitle.alert')).setPayload({ wallet: wallet })">
                    <g-symbol name="delete" width="22" height="22"/>
                </g-button>
                -->
            </div>
            <!-- /ACTIONS -->
        </template>

        <template v-else>
            <!-- CARD -->
            <div class="activate">
                <g-button class="control-btn" v-on:click="createWallet">
                    {{ $t('wallet.activate') }}
                </g-button>
            </div>
        </template>
    </div>
</template>
<script>

import { computed } from 'vue';
import { i18n, PopUpManager as PopUp } from '@/Ship';
import { useWalletComponents, useWalletActions } from '@wallet';

const {
    getCoinResource,
    getWalletResource
} = useWalletActions();

export default {
    name: 'SubWallet',
    props: {
        currency: {
            type: String,
            required: true,
        },
        network: {
            type: String,
            required: true,
        },
        idx: {
            type: Number,
        }
    },
    components: {
        ...useWalletComponents()
    },

    setup(props) {
        const wallets = getWalletResource();
        const walletIndex = wallets.state.findIndex((item) => item.coin.symbol === props.currency && item.coin.network === props.network);
        //const wallet = wallets.item(walletIndex);

        const wallet = computed(() => {
            const walletIndex = wallets.state.findIndex((item) => item.coin.symbol === props.currency && item.coin.network === props.network);

            return walletIndex > -1
                ? wallets.state[walletIndex]
                : null;
        })

        const balance = computed(() => {
            return wallet.value && wallet.value.balance - wallet.value.hold_balance;
        });

        const openSendPopUp = () => {
            wallets.index = walletIndex;
            PopUp.open(require('@wallet/components/parts/Withdraw')).setCaption(i18n.global.getLocaleMessage(i18n.global.locale.value).popupTitle.send);
        };

        function createWallet() {
            const coin = getCoinResource();
            const coinId = coin.state.find((item) => item.symbol === props.currency && item.network === props.network).id;
            wallets.create({ coin_id: coinId });
        }

        return {
            PopUp,
            wallet,
            balance,
            createWallet,
            openSendPopUp,
        };
    }
};
</script>

<style lang="scss" scoped>
.wallet-wrapper {
    .address > .g-caption {
        color: var(--title-text-color, $title-text-color);
    }

    .activate > .g-button {
        padding: 0 40px;
        color: var(--title-text-color, $title-text-color);
        background-color: var(--increase, $increase);
    }

    & > .actions {
        display: flex;

        .g-button {
            margin: 5px 0;
            padding: 0 20px;
        }

        .withdraw-btn {
            border: 1px solid var(--sell, $sell);
            background-color: transparent;

            & > svg {
                fill: var(--sell, $sell);
            }
        }

        .receive-btn {
            border: 1px solid var(--buy, $buy);
            background-color: transparent;

            & > svg {
                fill: var(--buy, $buy);
            }
        }

        .remove-btn {
            border: 1px solid var(--main-text-color, $main-text-color);
            background-color: transparent;

            & > svg {
                fill: var(--main-text-color, $main-text-color);
            }
        }
    }
}

@media (min-width: 785px) {
    .wallet-wrapper {
        display: grid;
        grid-gap: 20px;
        grid-template:
            'crd adr act' 58px
            'crd rpl act' 140px / 340px auto 68px;

        & > .wallet-card {
            grid-area: crd;
        }

        & > .address {
            grid-area: adr;
        }

        & > .activate,
        & > .replenishing {
            grid-area: rpl;
        }

        & > .actions {
            grid-area: act;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
        }
    }
}

@media (max-width: 784px) {
    .wallet-card,
    .address,
    .replenishing,
    .actions {
        margin: 20px auto;
        max-width: 380px;
    }

    .actions {
        justify-content: space-between;
    }
}
</style>
