

export default function(price, amount, trend) {
	
	
	
    return {
        price,
        amount,
        type: trend
    };
}