<template>
    <label class="g-switch"
           :class="[propDisabled]">
        <input type="checkbox"
               :checked="checked"
               :disabled="disabled"
               @input="inputEvent"
               hidden>
        <span></span>
    </label>
</template>

<script>
import { computed, toRefs } from 'vue';

export default {
    name: 'g-switch',
    props: {
        disabled: {
            type: Boolean,
            default: false
        },

        checked: {
            type: Boolean,
        },
    },
    emits: ['update:checked'],

    setup(props, { emit }) {
        const { disabled } = toRefs(props);

        const propDisabled = computed(() => {
            return disabled.value ? 'g-switch-disabled' : '';
        });

        const inputEvent = event => {
            emit('update:checked', event.target.checked);
        };

        return {
            propDisabled,
            inputEvent
        };
    }
};
</script>

<style lang="scss" scoped>
.g-switch {
    position: relative;
    cursor: pointer;
    display: inline-flex;
    width: var(--switch-width, $switch-width);
    height: var(--switch-height, $switch-height);

    & > span {
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        border-radius: var(--switch-rounding, $switch-rounding);

        &::after {
            content: '';
            position: absolute;
            left: 2px;
            top: calc((100% - var(--switch-thumb, $switch-thumb)) / 2);
            height: var(--switch-thumb, $switch-thumb);
            width: var(--switch-thumb, $switch-thumb);
            border-radius: 50%;
            background-color: var(--switch-thumb-bg, $switch-thumb-bg);
            transition: all 0.2s;
        }
    }

    & > input:not(:checked) + span {
        border: 1px solid var(--separator-color, $separator-color);
        background-color: var(--switch-bg-color, $switch-bg);
    }

    & > input:checked + span {
        background: var(--switch-active-bg-color, $switch-active-bg);

        &::after {
            left: calc(100% - var(--switch-circle, $switch-thumb) - 2px);
        }
    }
}

.g-switch-disabled {
    cursor: default;
    pointer-events: none;

    & > span {
        background: var(--switch-disabled-color, $switch-disabled-bg);
    }

    & > input:checked + span {
        background: var(--switch-disabled-active-bg, $switch-disabled-active-bg);
    }
}
</style>
