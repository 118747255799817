
import { useQuoteResources } from '@quotes';

export default function(reload = false) {
    const market = useQuoteResources('Market');
    if (reload || (!market.isLoaded && !market.isLoading)) {
        market.load();
    }
	
    return market;
}
