
import { ref } from 'vue';

export default class Order {

    static #instance = null;

    section = 'Trading';
    container = 'OrderBook';
	
	buysCollection = ref([]);
	sellsCollection = ref([]);
	
	dropSells = ref([]);
	dropBuys = ref([]);
	
    constructor() {
        if (!Order.#instance) {
            Order.#instance = this;
        }

        return Order.#instance;
    }

    static getInstance() {
        return new Order();
    }
	
	get buys() {
		return this.buysCollection.value;
	}
	
	set buys(data) {
		this.buysCollection.value = data;
	}
	
	get sells() {
		return this.sellsCollection.value;
	}
	
	set sells(data) {
		this.sellsCollection.value = data;
	}
	
	get topBuy() {
		return this.buysCollection.value[this.buysCollection.value.length - 1];
	}
	
	set topBuy(data) {
		this.buysCollection.value[this.buysCollection.value.length - 1] = data;
	}
	
	get topSell() {
		return this.sellsCollection.value[0];
	}
	
	set topSell(data) {
		this.sellsCollection.value[0] = data;
	}
}
