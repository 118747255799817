<template>
    <div class="history">
        <g-caption size="2">History</g-caption>
        <table>
            <thead>
            <tr class="titles">
                <th class="cell date">Date</th>
                <th class="cell amount">Amount</th>
                <th class="cell usd">USD</th>
                <th class="cell status">Status</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(row, i) in mock" v-bind:key="`dep-history-${i}`">
                <td class="cell date">{{ row.date }}</td>
                <td class="cell amount">{{ row.amount }}</td>
                <td class="cell usd">{{ row.USD }}</td>
                <td class="cell status">{{ row.status }}</td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: "History",

    setup() {
        const mock = [
            {
                address: "1BhYT3eXSZscQeAk3gAS9oLqHzJnYtq7Q7",
                amount: '0.001 BTC',
                USD: '$1000001',
                date: '2022-12-22 18:32',
                status: 'Error',
            },
            {
                address: "1BhYT3eXSZscQeAk3gAS9oLqHzJnYtq7Q7",
                amount: '0.01 BTC',
                USD: '$1001',
                date: '2022-12-21 18:32',
                status: 'Processed',
            },
            {
                address: "0x145fd617a926318d8fcc43ad223220220530805b",
                amount: '0.0000002 BTC',
                USD: '$1001',
                date: '2022-12-20 18:32',
                status: 'Processed',
            },
            {
                address: "1BhYT3eXSZscQeAk3gAS9oLqHzJnYtq7Q7",
                amount: '0.01 BTC',
                USD: '$1001',
                date: '2022-12-18 18:32',
                status: 'Processed',
            },
            {
                address: "1BhYT3eXSZscQeAk3gAS9oLqHzJnYtq7Q7",
                amount: '0.001 BTC',
                USD: '$1001',
                date: '2022-12-22 18:32',
                status: 'Refaund',
            },
            {
                address: "1BhYT3eXSZscQeAk3gAS9oLqHzJnYtq7Q7",
                amount: '0.0000001 BTC',
                USD: '$1001',
                date: '2022-12-21 18:32',
                status: 'Processed',
            },
            {
                address: "0x145fd617a926318d8fcc43ad223220220530805b",
                amount: '0.2 BTC',
                USD: '$1001',
                date: '2022-12-20 18:32',
                status: 'Processed',
            },
            {
                address: "1BhYT3eXSZscQeAk3gAS9oLqHzJnYtq7Q7",
                amount: '0.01 BTC',
                USD: '$1001',
                date: '2022-12-18 18:32',
                status: 'Processed',
            },
            {
                address: "1BhYT3eXSZscQeAk3gAS9oLqHzJnYtq7Q7",
                amount: '0.01 BTC',
                USD: '$1001',
                date: '2022-12-18 18:32',
                status: 'Processed',
            },
            {
                address: "1BhYT3eXSZscQeAk3gAS9oLqHzJnYtq7Q7",
                amount: '0.01 BTC',
                USD: '$1001',
                date: '2022-12-18 18:32',
                status: 'Processed',
            },
            {
                address: "1BhYT3eXSZscQeAk3gAS9oLqHzJnYtq7Q7",
                amount: '0.001 BTC',
                USD: '$1001',
                date: '2022-12-22 18:32',
                status: 'Processed',
            },
            {
                address: "1BhYT3eXSZscQeAk3gAS9oLqHzJnYtq7Q7",
                amount: '0.01 BTC',
                USD: '$1001',
                date: '2022-12-21 18:32',
                status: 'Processed',
            },
            {
                address: "0x145fd617a926318d8fcc43ad223220220530805b",
                amount: '0.2 BTC',
                USD: '$1001',
                date: '2022-12-20 18:32',
                status: 'Processed',
            },
            {
                address: "1BhYT3eXSZscQeAk3gAS9oLqHzJnYtq7Q7",
                amount: '0.01 BTC',
                USD: '$1001',
                date: '2022-12-18 18:32',
                status: 'Processed',
            },
            {
                address: "1BhYT3eXSZscQeAk3gAS9oLqHzJnYtq7Q7",
                amount: '0.01 BTC',
                USD: '$1001',
                date: '2022-12-18 18:32',
                status: 'Processed',
            },
            {
                address: "1BhYT3eXSZscQeAk3gAS9oLqHzJnYtq7Q7",
                amount: '0.01 BTC',
                USD: '$1001',
                date: '2022-12-18 18:32',
                status: 'Processed',
            },
            {
                address: "1BhYT3eXSZscQeAk3gAS9oLqHzJnYtq7Q7",
                amount: '0.001 BTC',
                USD: '$1001',
                date: '2022-12-22 18:32',
                status: 'Processed',
            },
            {
                address: "1BhYT3eXSZscQeAk3gAS9oLqHzJnYtq7Q7",
                amount: '0.01 BTC',
                USD: '$1001',
                date: '2022-12-21 18:32',
                status: 'Processed',
            },
            {
                address: "0x145fd617a926318d8fcc43ad223220220530805b",
                amount: '0.2 BTC',
                USD: '$1001',
                date: '2022-12-20 18:32',
                status: 'Processed',
            },
            {
                address: "1BhYT3eXSZscQeAk3gAS9oLqHzJnYtq7Q7",
                amount: '0.01 BTC',
                USD: '$1001',
                date: '2022-12-18 18:32',
                status: 'Processed',
            },
            {
                address: "1BhYT3eXSZscQeAk3gAS9oLqHzJnYtq7Q7",
                amount: '0.01 BTC',
                USD: '$1001',
                date: '2022-12-18 18:32',
                status: 'Processed',
            },
            {
                address: "1BhYT3eXSZscQeAk3gAS9oLqHzJnYtq7Q7",
                amount: '0.01 BTC',
                USD: '$1001',
                date: '2022-12-18 18:32',
                status: 'Processed',
            },
        ];

        return {
            mock
        }
    }
}
</script>

<style lang="scss" scoped>
.history {
    height: 100%;

    table {
        text-align: center;
        white-space: nowrap;

        th {
            padding-bottom: 20px;
        }
    }
}

.g-caption {
    margin: 0 1vw 20px;
}

@media (min-width: 1225px) {
    table {
        padding-right: 30px;

        th {
            font-size: 20px;
        }

        td {
            padding: 12px 1vw;
        }
    }
}

@media (max-width: 1224px) {
    table {
        padding-right: 10px;

        th {
            font-size: 18px;
        }

        td {
            padding: 12px 0.5vw;
        }
    }
}

@media (min-width: 445px) {
    table {
        overflow: auto;
        display: block;
        height: calc(100% - 120px);
    }
}

@media (max-width: 444px) {
    table {
        width: 100%;
    }

    td {
        font-size: 13px;
    }

    th.usd,
    td.usd {
        display: none;
    }
}
</style>
