<template>
    <div class="counter-currencies__list-close-area"
         v-if="listOpened"
         @click="listOpened = false">
    </div>

    <div class="counter-currencies">
        <g-star-filter :selected="starFilter" @click="changeStarFilterStatus"/>

        <span v-for="currency in favoritesPartOfCurrencies"
              v-bind:key="currency"
              v-on:click="$emit('update:modelValue', currency)"
              v-bind:class="{active: currency === modelValue}"
              class="counter-currencies__item">
            {{ currency }}
        </span>

        <span class="icomoon-arrow list-arrow"
              v-if="othersecondPartOfCurrencies.length > 0"
              @click="listOpened = !listOpened"
              :class="listOpened ? 'list-arrow-up' : 'list-arrow-down'">
        </span>

        <transition name="fade">
            <div class="counter-currencies__list"
                 v-show="listOpened && othersecondPartOfCurrencies.length > 0">
                <span class=""
                    v-for="(currency, idx) in othersecondPartOfCurrencies"
                    :key="idx"
                    :class="{active : currency === modelValue}"
                    @click="selectCurrentCurrency(currency)">
                    {{ currency }}
                </span>
            </div>
        </transition>
    </div>
</template>

<script>
import {computed, ref} from 'vue';

export default {
    name: 'CounterCurrencies',
    props: ['modelValue'],
    emits: ['update:modelValue'],

    setup(props, { emit }) {

        const listOpened = ref(false)

        const selectCurrentCurrency = currency => {
            emit('update:modelValue', currency);
            listOpened.value = false;
        };

        // TODO Take this data from the Company Resource
        const currencies = [
            'USDT',
            'BTC',
            'ETH',
            'ETf',
            'rTC',
        ];

        const favoritesPartOfCurrencies = computed(() => {
            if (currencies.length >= 5) {
                return currencies.slice(0, 5);
            } else {
                return currencies.slice(0, currencies.length);
            }
        });

        const othersecondPartOfCurrencies = computed(() => {
            if (currencies.length >= 5) {
                return currencies.slice(5, currencies.length);
            } else {
                return [];
            }
        });

        return {
            currencies,
            listOpened,
            selectCurrentCurrency,
            favoritesPartOfCurrencies,
            othersecondPartOfCurrencies,
        };
    }
};
</script>

<style lang="scss" scoped>
.counter-currencies {
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.5rem;
    position: relative;

    &__list {
        width: 32rem;
        columns: 5;
        right: 3%;
        border-radius: 0.5rem;
        border: 1px solid;
        backdrop-filter: blur(0.6rem);
        padding: 1.5rem 2rem;
        z-index: 10;
        top: 100%;
        position: absolute;

        & > span {
            font-size: 1.4rem;
            text-transform: uppercase;
            cursor: pointer;
            display: inline-block;
            margin-right: 1.75rem;
        }
    }

    &__list-close-area {
        position: fixed;
        width: 100vw;
        height: 100vh;
    }

    &__item {
        text-align: center;
        padding: 0.625rem;
        cursor: pointer;
        text-transform: uppercase;
    }
}

.list-arrow {
    font-size: 1.6rem;
    cursor: pointer;

    &-up {
        transform: rotate(-180deg);
    }
}
</style>