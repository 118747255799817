export default function(bar) {

    return {
        //time: bar.time || Date.now(),
        //open: bar.open || previous.open,
        // high: bar.p > previous.heigh ? bar.p : previous.heigh,
        // low: bar.p < previous.low ? bar.p : previous.low,
        // close: bar.p,
        //high: bar.close > previous.heigh ? bar.close : previous.heigh,
        //low: bar.close < previous.low ? bar.close : previous.low,
        //close: bar.close,

        time: bar.openTime || Date.now(),
        open: bar.open,
        high: bar.high,
        low: bar.low,
        close: bar.close,
        volume: bar.volume,
    };
}