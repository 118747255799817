import {
    i18n,
    STATE,
} from '@/Ship';

import {
    createRouter,
    createWebHistory,
} from 'vue-router';

import { useAuthTasks } from '@auth';
const { saveToken, reloadProfile } = useAuthTasks();

const requireRoutes = require.context('@/Containers/', true, /routes\.js$/);

const guardException = [
    'SignIn',
    'SignUp',
    'ResetPassword',
    'style-kit',
];

const findAllRelation = (rootPath, allPath) => {
    return rootPath.map((root) => {
        const rootChildren = (allPath, root) => { // Find all child for root section
            return allPath.reduce((acc, cur) => {
                if (root.parent === cur.parent && root.child !== cur.child) {
                    acc.push(cur);
                }
                return acc;
            }, []);
        };

        return {
            path: root.path,
            children: rootChildren(allPath, root),
        };
    });
};

const requireAllRoutes = (relationFile) => {

    return relationFile.map(route => {
        const componentConfig = requireRoutes(route.path);

        if (route.children.length) { //Checking for the existence of children
            componentConfig.default[0].children = route.children
            .map((file) => requireRoutes(file.path).default)
            .flat(); //require wrap in an array
        }

        return componentConfig.default;
    }).flat(); //webpack "require" wrap in an array
};

const splitFileName = (path) => {
    const parseUrl = path.split('/'); //Replace with regEx
    return { parent: parseUrl[1], child: parseUrl[2], path };
};

const satisfyRoot = (parent, child) => parent === child; //Satisfy for root section

const routesArr = requireRoutes.keys().map((routes) => splitFileName(routes)); //Split all path
const sectionRoot = routesArr.filter((path) => { //Filter root section
    return satisfyRoot(path.parent, path.child);
});

const relationAllFile = findAllRelation(sectionRoot, routesArr);

const routes = requireAllRoutes(relationAllFile);

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    if (from.params.locale !== to.params.locale) {
        i18n.selectLocale(to.params.locale || process.env.VUE_APP_DEFAULT_LOCALE);
    }

    if (
        (to.params.locale === process.env.VUE_APP_DEFAULT_LOCALE) &&
        i18n.global.fallbackLocale.value.includes(to.params.locale)
    ) {
        const path = to.path.replace(new RegExp(`^/${to.params.locale}`), '');
        next({ name: to.name, redirect: path });
    } else if (to.name === 'Impersonate' || to.name === 'Autologin') {
        setTimeout(() => {
            sessionStorage.login_type = to.name;

            saveToken({
                access_token: to.query.token,
                //refresh_token: to.query.refresh_token,
                expires_in: to.query.expires_in,
            });
            location.href = '/';
            //reloadProfile();
            //STATE.IS_LOGIN = true;
            //next({ name: 'Trading'});
        }, 500);
    } else {
        guardException.every(pageName => pageName !== to.name) && !STATE.IS_LOGIN
            ? next({ name: 'SignIn' })
            : next();
    }
});

export default router;
