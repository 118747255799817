<template>
    <div>
        <div class="text">{{ $t('wallet.popupFaq') }} <b>{{ wallet.name }}</b>?</div>
        <div class="footer">
            <g-button icon-color="danger" @click="closePopup">
                <g-symbol name="close" width="24" height="24"/>
                {{ $t('button.cancel') }}
            </g-button>
            <g-button fill="danger" @click="deleteWallet">
                <g-symbol name="delete" width="22" height="22"/>
                {{ $t('button.delete') }}
            </g-button>
        </div>
    </div>
</template>

<script>
import { useWalletActions } from '@wallet';

const { getWalletResource } = useWalletActions();

export default {
    name: 'RemoveWallet',
    emits: ['performed'],
    props: {
        wallet: {
            type: Object
        }
    },

    setup(props, { emit }) {
        const walletResource = getWalletResource();

        const deleteWallet = () => {
            let walletIndex = walletResource.state.indexOf(props.wallet);
            walletResource.delete(props.wallet.id).then(() => {
                walletResource.collection.value.splice(walletIndex, 1);
            });

            emit('performed');
        };

        function closePopup() {
            emit('performed');
        }

        return {
            deleteWallet,
            closePopup
        };
    }
};
</script>

<style scoped lang="scss">
.text {
    font-size: var(--text-lg, $text-lg);
    line-height: 150%;
}

.footer {
    display: flex;
    justify-content: space-between;
    gap: 22px;
    margin-top: 32px;

    &:deep(.g-button) {
        margin: 0;
        width: 100%;

        &:first-child {
            border: 1px solid var(--main-text-color, $main-text-color);
            background-color: transparent;
            color: var(--main-text-color, $main-text-color);

            svg {
                fill: var(--danger, $danger);
            }
        }

        &:last-child {
            border: 1px solid var(--danger, $danger);
            color: var(--title-text-colo, $title-text-color);
            background-color: var(--danger, $danger);

            svg {
                fill: var(--title-text-colo, $title-text-color);
            }
        }
    }
}

@media screen and (max-width: 460px) {
    .g-popup .footer {
        flex-direction: column;
        gap: 12px;

        &:deep(.g-button) {
            margin: 0;
        }
    }
}
</style>
