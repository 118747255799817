
export default function (str) {
    const validators = [
        str => /[a-z]+/g.test(str) && /[A-Z]+/g.test(str),
        str => /[0-9]+/g.test(str),
        str => /[?^/|`~*&@%#$+!'"]+/.test(str),
        str => str.length > 7,
    ];

    return validators.reduce((level, validator) => {
        return validator(str)
            ? level
            : level >> 1;
    }, 15);
}