
import { useOrderBookActions } from '@order-book';

const { getOrderResource } = useOrderBookActions();

export default function(stream) {

    const orders = getOrderResource();

    stream.forEach(order => {
        const idx = orders.state.findIndex(item => item.id === order.id);
        if (idx > -1) {
            Object.assign(orders.state[idx], order);
        }
    });
}
