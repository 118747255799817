
import { ref } from 'vue';

export default class Trade {

    static #instance = null;

    section = 'Trading';
    container = 'OrderBook';
	
	collection = ref([]);

	constructor() {
	    if (!Trade.#instance) {
            Trade.#instance = this;
	    }

        return Trade.#instance;
	}

    static getInstance() {
        return new Trade();
    }
	
	get state() {
	    return this.collection.value;
	}
	
	set state(data) {
	    this.collection.value = data;
	}
}
