<template>
    <ul class="g-pagination">
        <li class="btn-prev"
            v-bind:class="{disabled: modelValue <= 1}"
            v-on:click="$emit('update:modelValue', 1)">
            <g-symbol name="arrow-first" width="12" height="12"/>
        </li>
        <li class="btn-prev"
            v-bind:class="{disabled: modelValue <= 1}"
            v-on:click="$emit('update:modelValue', modelValue - 1)">
            <g-symbol name="arrow-previous" width="12" height="12"/>
        </li>
        <template v-if="total > 9">
            <li v-for="page in beginning.length ? beginning : 3"
                v-bind:key="`page-${page}`"
                v-bind:class="{ active: modelValue === page }"
                @click="$emit('update:modelValue', page)">
                {{ page }}
            </li>
            <li v-if="modelValue < total - 4">...</li>
            <li v-for="page in end"
                v-bind:key="`page-${page}`"
                v-bind:class="{ active: modelValue === page }"
                @click="$emit('update:modelValue', page)">
                {{ page }}
            </li>
        </template>
        <template v-else>
            <li v-for="page in total"
                v-bind:key="`page-${page}`"
                v-bind:class="{ active: modelValue === page }"
                @click="$emit('update:modelValue', page)">
                {{ page }}
            </li>
        </template>
        <li class="btn-next"
            v-bind:class="{disabled: modelValue >= total}"
            v-on:click="$emit('update:modelValue', modelValue + 1)">
            <g-symbol name="arrow-next" width="12" height="12"/>
        </li>
        <li class="btn-next"
            v-bind:class="{disabled: modelValue >= total}"
            v-on:click="$emit('update:modelValue', total)">
            <g-symbol name="arrow-last" width="12" height="12"/>
        </li>
    </ul>
</template>

<script>
import { computed } from 'vue';

export default {
    name: 'GPagination',
    components: {},
    props: {
        total: {
            type: Number,
            required: true
        },
        modelValue: {
            type: Number,
            default: 1
        },
    },

    emits: ['update:modelValue'],

    setup(props) {

        const pages = computed(() => {
            const pages = [];
            for (let i = 1; i <= props.total; i++) {
                pages.push(i);
            }

            return pages;
        });

        const end = computed(() => {
            return pages.value.slice(props.total - 3, props.total);
        });

        const beginning = computed(() => {
            return props.modelValue < props.total - 3
                ? pages.value.slice(props.modelValue - 2, props.modelValue + 1 )
                : pages.value.slice(props.total - 6, props.total - 3);
        });

        return {
            end,
            beginning
        }
    }


};
</script>

<style lang="scss" scoped>
.g-pagination {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    li {
        padding: 0;
        height: 20px;
        line-height: 20px;
        cursor: pointer;
        color: var(--label-color, $label-color);
        fill: var(--label-color, $label-color);

        &:hover {
            color: var(--title-text-color, $title-text-color);
            fill: var(--title-text-color, $title-text-color);
            transform: scale(1.2);
        }

        &.active {
            font-weight: 700;
            color: var(--primary-hover, $primary-hover);
            pointer-events: none;
        }
    }
}

.btn-prev,
.btn-next {
    font-size: 0;
    margin: 0 2px;

    &.disabled {
        opacity: 0.4;
        pointer-events: none;
    }

    & > .g-symbol {
        margin-top: 5px;
    }
}
</style>