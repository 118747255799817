import {reactive, ref} from 'vue';
import { ApiClient, i18n, STACK, WebSockets as Stream} from '@/Ship';
import { useAuthActions } from '@auth';

import ValidRules from '@/Ship/core/ValidRules';

const validRules = new ValidRules();

export default class Profile {

    static #instance = null;
    endpoint = '/profile';

    rules = {
        first_name: 'require | minLength:2',
        last_name: 'require | minLength:2',
        email: 'require | email',
    };
    validate = reactive({});
    model = reactive({});
    //entity = reactive({});
    entity = {};
    loading = ref(false);
    loaded = ref(false);

    constructor() {
        Profile.#instance = new Proxy(this.model, {
            get: (target, key) => {
                return key in this.model
                    ? this.model[key]
                    : this[key] || undefined;
            },
            set: (target, key, value) => {
                // Todo add proxi object
                this.entity[key] = value;
                this.model[key] = value;
                this.initValidation(key, value);
                return true;
            }
        });

        return Profile.#instance;
    }

    static getInstance() {
        return Profile.#instance
            ? Profile.#instance
            : new Profile;
    }

    get isLoaded() {
        return this.loaded.value;
    }

    get isLoading() {
        return this.loading.value;
    }

    load(params = {}) {

        this.loaded.value = false;
        this.loading.value = true;

        return STACK.push(() => ApiClient.get(this.endpoint, { params })).then(response => {

            this.loaded.value = true;
            this.loading.value = false;

            const payload = response.data;
            payload.balance = Number(payload.balance);
            Object.assign(this.model, payload);
            Object.assign(this.validate, this.rules);

            const stream = Stream.getInstance(process.env.VUE_APP_WEBSOCKET);

            stream.subscribe({ profile: { id: payload.id, broker: false, /*session_id: 297018*/}});

            stream.addHandlers({
                profile: payload => {
                    payload.balance = Number(payload.balance);
                    Object.assign(this.model, payload);
                },
            });
        });

    }

    save() {
        if (this.isValidationPassed()) {
            STACK.push(() => ApiClient.patch(`${this.endpoint}`, this.entity));
        }
    }

    delete() {
        STACK.push(() => ApiClient.delete(`${this.endpoint}/${this.model.id}`).then(() => {
            const signOut = useAuthActions('signOut');
            signOut();
        }));
    }

    clearEntity() {
        Object.keys(this.entity).forEach(key => delete this.entity[key]);
    }

    // initNestedValidation(key, collection) {
    //     if (this.rules[key] && collection) {
    //         collection.forEach((item, i) => {
    //             this.validate.api_clients[i].service_url = this.errors(this.rules['service_url'], item.service_url);
    //         });
    //     }
    // }

    initValidation(key, value) {
        if (this.rules[key]) {
            this.validate[key] = this.errors(this.rules[key], value);
        }
    }

    errors(rules, value) {
        let errorList = [];
        let processedRules = typeof rules === 'object' ? rules : [];

        if (typeof rules === 'string') {
            const separatedRules = rules.split('|');

            separatedRules.forEach(rule => {
                rule = rule.replace(/\s/g, '');
                let isRuleHasParam = rule.indexOf(':') > -1 ? rule.split(':') : rule;

                processedRules.push(isRuleHasParam);
            });
        }

        processedRules.forEach((rule, index) => {
            if (typeof rule === 'object') {
                let param = rule[1];

                validRules[rule[0]](value, param)
                    ? errorList.splice(index, 1)
                    : errorList = [i18n.global.t(`validator.${rule[0]}`)];
            }

            if (typeof rule === 'string') {
                validRules[rule](value)
                    ? errorList.splice(index, 1)
                    : errorList = [i18n.global.t(`validator.${rule}`)];
            }
        });

        return errorList;
    }

    isValidationPassed = () => {
        let isValid = true;
        for (let key in this.validate) {
            isValid = (Array.isArray(this.validate[key]) && !this.validate[key].length)
                || this.validate[key] === this.rules[key];

            if (!isValid) break;
        }
        return isValid;
    };
}
