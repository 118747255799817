<template>
    <div class="tickers">
        <markets v-model="marketName"/>
        <component v-bind:is="marketName"
                   v-bind:symbol="{base: ticker.base, counter: ticker.counter}"
                   v-bind:sortable="'price'"/>
    </div>
</template>

<script>

import { computed, defineProps } from 'vue';
import { STATE } from '@/Ship';
import { useQuoteActions, useQuoteComponents } from '@quotes';

defineProps(['marketName']);

const {
    getCryptoResource,
    getStockResource,
    getForexResource,
    getCommodityResource,
} = useQuoteActions();

const {
    Crypto,
    Stocks,
    Forex,
    Commodities,
    Markets,
} = useQuoteComponents();

export default {
    name: 'View',
    components: {
        Crypto,
        Stocks,
        Forex,
        Commodities,
        Markets,
    },
    props: {
        marketName: {
            type: String,
            default: 'crypto'
        }
    },

    setup() {
        const resources = {
            crypto: getCryptoResource,
            stocks: getStockResource,
            forex: getForexResource,
            commodities: getCommodityResource,
        };


        const ticker = computed(() => {
            const resource = resources[STATE.MARKET]();
            return resource.state[resource.index] || resource.model;
        });

        return {
            ticker,
        };
    }
};
</script>

<style lang="scss" scoped>
.markets {
    z-index: 2;
}

.quotes {
    z-index: 3;
    width: 100%;
    position: relative;
    container-type: inline-size;
}

.active-ticker {
    width: 100%;
    height: 100%;
    padding: 0 20px;
    line-height: 1.1;
    background-image: linear-gradient(to right, #00000000, var(--bar-bg, $bar-bg));
    box-shadow: 0 5px 20px -5px #000000aa;
}

.ticker-symbol {
    padding: 8px;
    column-gap: 6px;
    display: flex;
    flex-wrap: wrap;
    align-content: start;
    justify-content: center;
    flex-direction: column;

    & > .symbol {
        & > .base-currency {
            font-weight: 600;
            color: var(--title-text-color, $title-text-color);
        }

        & > .g-symbol {
            margin-left: 5px;
            vertical-align: middle;
            transform: rotate(90deg);
            fill: var(--title-text-color, $title-text-color);
        }
    }
}

.price {
    margin-left: 40px;
    font-weight: 600;
    text-align: right;

    & > .percent {
        font-weight: 400;
        color: var(--main-text-color, $main-text-color);
    }

    &.decrease > .percent::before {
        transform: rotate(45deg);
    }

    &.increase > .percent::before {
        transform: rotate(-45deg);
    }
}

.percent::after {
    content: '%';
}

.percent::before {
    content: '⮕';
    width: 16px;
    height: 16px;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    display: inline-block;
    transition: transform 0.2s;
    font-weight: bold;
    margin-right: 5px;
}

.increase {
    color: var(--increase, $increase);
}

.decrease {
    color: var(--decrease, $decrease);
}

.specifics {
    margin-top: 5px;
    font-size: 14px;
    text-align: center;
    white-space: nowrap;

    & > :first-child {
        text-align: left;
    }

    & > :last-child {
        text-align: right;
    }

    .title {
        opacity: 0.7;
        font-size: 11px;
    }
}

@container (min-width: 680px) {
    .ticker-symbol {
        height: 68px;
        width: 240px;

        .g-symbol-currency {
            width: 68px;
            height: 68px;
        }

        & > .name {
            font-size: 1.2rem;
        }

        & > .symbol {
            font-size: 1.6rem;
        }
    }

    .price {
        & > .value {
            font-size: 1.6rem;
        }

        & > .percent {
            font-size: 1.2rem;
        }
    }
}

@container (max-width: 680px) {
    .ticker-symbol {
        height: 60px;
        width: 186px;

        .g-symbol-currency {
            width: 42px;
            height: 42px;
        }

        & > .name {
            font-size: 1rem;
        }

        & > .symbol {
            font-size: 1.3rem;
        }
    }

    .price {
        & > .value {
            font-size: 1.3rem;
        }

        & > .percent {
            font-size: 1rem;
        }
    }
}

@container (max-width: 560px) {
    .specifics {
        display: none !important;
    }
}
</style>