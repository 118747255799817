
import { usePositionResources } from '@position';

export default function(reload = false) {

    const history = usePositionResources('History');

    if (reload || (!history.isLoaded && !history.isLoading)) {
        history.setIncludes(['position','pair']);
        history.sort('created_at','desc');
        history.load();
    }

    return history;
}