
import { useWalletResources } from '@wallet';

export default (reload = false) => {
    const coin = useWalletResources('Coin');
    if (reload || (!coin.isLoaded && !coin.isLoading)) {
        coin.load();
    }

    return coin;
};
