
import componentLoader from '@/Ship/core/ComponentLoader';

//--- Components ---//
export function useEnvComponents(ask = null) {
    const requireComponents = require.context('./environment', true, /[\w-]+\.vue$/);

    return ask
        ? componentLoader(requireComponents)[ask]
        : componentLoader(requireComponents);
}

export function useCoverComponents(ask = null) {
    const requireComponents = require.context('./cover', true, /[\w-]+\.vue$/);

    return ask
        ? componentLoader(requireComponents)[ask]
        : componentLoader(requireComponents);
}

