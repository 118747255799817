import { STATE } from '@/Ship/';
import { defineAsyncComponent } from 'vue';

export default (popUpPath) => {
    STATE.POPUP = defineAsyncComponent(() => new Promise(resolve => {
        resolve(popUpPath);
    }));
};


