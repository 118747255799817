
import { reactive } from 'vue';
import { useShipResources } from '@/Ship';
const { BaseResource } = useShipResources();


export default class Transaction extends BaseResource {

    static #instance = null;

    endpoint = `${process.env.VUE_APP_WALLET_SERVER}/client/transactions`;

    section = "Finances";
    container = "Wallet";


    filters = reactive({});

    static getInstance() {
        if (!Transaction.#instance) {
            Transaction.#instance = new Transaction();
        }
        return Transaction.#instance;
    }
}
