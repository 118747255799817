
import { useShipResources } from '@/Ship';
import {reactive, ref} from 'vue';

const { BaseResource } = useShipResources();

export default class Market extends BaseResource {

	static #instance = null;

	endpoint = '/markets';

	section = 'Trading';
	container = 'Quotes';
	resource = 'Market';
	
	constructor() {
		super();
	    Market.#instance = new Proxy(this.model, {
			get: (target, key) => {
				const market = this.collection.value.find(market => market.name === key);
				return market || this.model[key] || this[key] || undefined;
			},
		});
		
		return Market.#instance;
	}

	static getInstance() {
		return Market.#instance
			? Market.#instance
			: new Market;
	}
	
	choose(marketName) {
		
		const index = this.collection.value.findIndex(market => market.name === marketName);
		
		if (index >= 0) {
			this.idx.value = index;
		}
	}
}
