<template>
    <div class="g-popup" v-on:click="PopUp.close()">
        <div class="popup" v-on:click.stop>
            <g-flex class="popup-header" justify="between" align="center">
                <g-caption size="3" weight="600" v-html="PopUp.caption"></g-caption>
                <g-symbol name="close" class="popup-close" v-on:click="PopUp.close()" height="24" width="24"/>
            </g-flex>
            <div class="popup-body" >
                <slot/>
            </div>
        </div>
    </div>
</template>

<script>

import { PopUpManager as PopUp } from '@/Ship';

export default {
    name: 'GPopup',
    components: {},

    setup() {
        return { PopUp };
    }
};
</script>

<style lang="scss" scoped>
.g-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    display: flex;
    overflow: auto;
    background-color: var(--popup-overlay, $popup-overlay);
}

.popup {
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    max-width: calc(100% - 10px);
    background-color: var(--popup-bg, $popup-bg);
    border: 1px solid var(--separator-color, $separator-color);
    border-radius: var(--popup-rounding, $popup-rounding);
    box-shadow: 0 10px 30px -5px #000000aa;
}

.popup-header {
    padding: 0 30px;
    //border-bottom: 1px solid var(--separator-color, $separator-color);
    background-color: var(--env-bg, $env-bg);

    .g-caption {
        margin-right: 20px;
    }
}

.popup-body {
    padding: 40px;
}

.popup-close {
    cursor: pointer;
    fill: var(--main-text-color, $main-text-color);

    &:hover {
        fill: var(--title-text-color, $title-text-color);
    }
}
</style>
