
import { useQuoteResources } from '@quotes';

export default function(reload = false) {
    const crypto = useQuoteResources('Crypto');
    if (reload || (!crypto.isLoaded && !crypto.isLoading)) {
        crypto.setIncludes(['asset']);
        crypto.load();
    }

    return crypto;
}
