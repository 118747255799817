
import { useProfileResources } from '@profile';

export default (reload = false) => {
	const country = useProfileResources('Country');
	if (reload || (!country.isLoaded && !country.isLoading)) {
		country.load();
	}

	return country;
};
