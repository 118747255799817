import {ApiClient, STACK, STATE} from '@/Ship';
import { useAuthTasks} from '@auth';
const { reloadProfile, deleteToken } = useAuthTasks();

export default function () {
    return STACK.push(() => ApiClient.delete('/logout')).then(() => {
        STATE.IS_LOGIN = false;
        STATE.IS_LIGHT_MODE = false;
        deleteToken();
        reloadProfile();
    });
}
