<script setup>
import { computed, ref, onMounted, defineEmits, defineProps, toRefs } from 'vue';
import { useShipActions } from '@/Ship';


const emit = defineEmits(['update:modelValue', 'update:country']);
const props = defineProps({
    modelValue: {
        type: String,
    },
    country: {
        type: String,
    },
    countries: {
        type: Array,
        default: () => []
    },
    label: {
        type: String
    },
});

const {
    phoneMask,
    onlyNumberInput,
} = useShipActions();


//const phone = ref(props.modelValue);
const countryCode = ref('');

const {
    modelValue: phone
} = toRefs(props);

//inheritAttrs: false,

const countryIndex = ref(null);

if (props.country) {
    countryIndex.value = props.countries.findIndex(item => item.code === props.country)
}

const telCode = computed(() => {
    return props.countries[countryIndex.value].tel_code;
});

const inputEvent = event => {
    emit('update:modelValue', phoneMask(`${phone.value}${event.key}`, telCode.value));
};

const setCountry = event => {
    emit('update:country', countryCode.value);
    emit('update:modelValue', phoneMask(phone.value, telCode.value));
}

onMounted(() => {
    if (props.modelValue) {
        const code = (/^\+/.test(props.modelValue))
            ? `\\${telCode.value}`
            : telCode.value.replace(/^\+/, '');
        const phoneNumber = props.modelValue.replace(new RegExp(`^${code}`), '');

        emit('update:modelValue', phoneMask(phoneNumber, telCode.value));
    }
});
</script>

<template>
    <div class="g-phone-input">

        <g-select v-model="countryCode"
                  v-model:index="countryIndex"
                  v-bind:label="$t('base.country')"
                  v-bind:options="countries"
                  v-on:change="setCountry"
                  option-text="name"
                  option-value="code"
                  return-item/>

        <g-field v-model="phone"
                 v-bind:label="label"
                 v-bind:disabled="!country"
                 v-bind:reset-btn="false"
                 v-on:keypress.prevent="inputEvent"
                 v-on:keypress="onlyNumberInput"
                 type="tel"/>
    </div>
</template>

<style lang="scss" scoped>
.g-phone-input {
    display: flex;
    align-items: flex-end;

    .g-select {
        max-width: 148px;
        margin-right: 5px;
    }

    .g-field {
        border: none;
        flex-grow: 1;
    }
}
</style>
