
import { useShipActions } from '@/Ship';

const probability = useShipActions('probability');

export default function(price, count = 10) {
	
    const base = Number(price).toString().split('.')[1]?.length || 2;
	
    const min = price - (price  / 100);
    const max = Number(price) + (price / 100);

    const trades = [];
    const trade = { price };
    while(trades.length < count) {
		
        trade.type = probability(trade.type === 'buy' ? 60 : 40) ? 'buy' : 'sell';
		
        const tradePrice = Number((trade.type === 'buy'
            ? Math.random() * (max - trade.price) + trade.price
            : Math.random() * (trade.price - min) + min
        ).toFixed(base < 2 ? 2 : base));

        if (tradePrice === trade.price && probability(75)) {
            continue;
        }
		
        trade.price = tradePrice;
        trade.amount = probability(20)
            ? Math.floor(Math.random() * (50 - 1) + 1) * 100
            : Math.floor(Math.random() * (10 - 1) + 1) * 100;
        trade.total = base > 3
            ? Math.floor(trade.amount / trade.price)
            : Number((trade.amount / trade.price).toFixed(5));

        trades.unshift({...trade});
    }

    return trades;
}
