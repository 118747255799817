
import { useChartActions } from '@chart';

const getBarResource = useChartActions('getBarResource');
export default function(quote) {
    const bar = getBarResource();

    if (!bar.idx.value) {
        return;
    }

    if (!bar.isLoaded || bar.isLoading) {
        return;
    }

    bar.price = quote.price || bar.price;
    bar.high = quote.high || bar.high;
    bar.low = quote.low || bar.low;
    bar.open = quote.open || bar.open;
    bar.volume_24 = quote.volume_24 || bar.volume_24;

    let resolution = bar.subscribe.resolution;
    if (bar.subscribe.resolution === '1D') {
        resolution = 1440;
    } else if (bar.subscribe.resolution === '1W') {
        resolution = 10080;
    }
    resolution *= 60000; // Convert to milliseconds
    //stream.time = stream.time * 1000; // Convert to milliseconds
    const quoteTime = Math.floor((quote.time || Date.now()) / resolution) * resolution; // Beginning of the period

    if (quoteTime > bar.model.time) {
        bar.idx.value = null;

        bar.update({limit: 2}).then(response => {
            response.data.forEach(([time, open, high, low, close, volume]) => {

                const kline = {
                    time: Number(time),
                    open: Number(open),
                    high: Number(high),
                    low: Number(low),
                    close: Number(close),
                    volume: Number(volume)
                };

                const index = bar.collection.value.findIndex(item => item.time === time);

                if (index > -1) {
                    Object.assign(bar.collection.value[index], kline);
                } else if (time > bar.collection.value[bar.collection.value.length - 1].time) {
                    setTimeout(() => {
                        bar.idx.value = bar.collection.value.push(kline) - 1;
                    }, 200);
                }
            });
        }).catch(() => {
            bar.idx.value = bar.collection.value.push({
                quoteTime,
                open: bar.price,
                close: bar.price,
                high: bar.price,
                low: bar.price,
                volume: 0,
                volume_24: bar.volume_24,
            }) - 1;
        });

        bar.volume = 0;
    } else {
        bar.state[bar.idx.value].close = bar.price;
        bar.state[bar.idx.value].volume_24 = bar.volume_24;
        if (quote.volume) {
            bar.state[bar.idx.value].volume += (quote.volume < bar.volume)
                ? 0
                : quote.volume - bar.volume;

            bar.volume = quote.volume;
        }

        if (bar.price > bar.model.high) {
            bar.state[bar.idx.value].high = bar.price;
        }
        if (bar.price < bar.model.low) {
            bar.state[bar.idx.value].low = bar.price;
        }
    }
}