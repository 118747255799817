<script setup>

import { ref, computed, onMounted, onUnmounted, defineProps } from 'vue';
import { useRouter } from 'vue-router';
import _ from 'lodash';
import { STATE, WebSockets as Stream } from '@/Ship';
import { useQuoteActions, useQuoteComponents } from '@quotes';
import { useChartActions } from '@chart';

const props = defineProps(['symbol', 'sortable', 'search']);

const {
    sortBy,
    getMarketResource,
    getForexResource,
    resolveQuotesStream
} = useQuoteActions();

const getBarResource = useChartActions('getBarResource');

const Ticker = useQuoteComponents('Ticker');

const bar = getBarResource();

const markets = getMarketResource();
const resource = getForexResource();

const tickers = computed(() => {
    const clonedQuotes = _.cloneDeep(resource.state.filter(item => {
        const searchFilter = props.search
            ? item.symbol.base.includes(props.search.toUpperCase())
            : true;

        //return (
        //    searchFilter &&
        //    item.symbol !== props.symbol
        //)

        return (
            searchFilter &&
            (item.symbol !== props.symbol)
        );
    }));

    // Sorting
    clonedQuotes.sort(
        sortBy(props.sortable || 'price')
    );

    return ref(clonedQuotes).value;
});

const router = useRouter();

function chooseQuote({ currentTarget: { dataset: {symbol} } }) {
    bar.state = [];
    bar.loaded.value = false;

    resource.choose(symbol);
    markets.choose('forex');
    STATE.MARKET = 'forex';
    STATE.SYMBOL = symbol;

    router.push({ params: {
        market: 'forex',
        base: resource.model.base.toLowerCase(),
        counter: resource.model.counter.toLowerCase(),
    } });
}

/** Stream **/
const stream = Stream.getInstance(process.env.VUE_APP_WEBSOCKET);

onMounted(() => {
    stream.subscribe({ quotes: { market: 'forex' }});
    stream.addHandlers({
        forex: resolveQuotesStream,
    });
});

onUnmounted(() => {
    stream.unsubscribe('quotes');
});
</script>

<template>
    <!-- Other Tickers -->
    <div class="tickers">
      <ticker v-for="ticker in tickers"
              v-bind:key="ticker.name"
              v-bind:ticker="ticker"
              v-bind:data-symbol="ticker.symbol"
              v-bind:data-market="ticker.market"
              v-on:click="chooseQuote"/>
    </div>
</template>
