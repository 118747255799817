<template>
    <currency-sprite/>

    <router-view/>
    <g-preloader-overlay v-if="STATE.IS_LOADING"/>
    <g-popup v-if="PopUp.component.value">
        <component v-bind:is="PopUp.component.value" v-bind="PopUp.payload" v-on:performed="PopUp.close()"></component>
    </g-popup>
</template>

<script>
import CurrencySprite from '@/Ship/components/currency-sprite';
import { PopUpManager as PopUp, STATE } from '@/Ship';

export default {
    name: 'Tipitek-APP',
    head: {
        link: [
            {
                rel: 'icon',
                href: require('@/Ship/assets/img/logo.svg')
            },
        ]
    },

    components: {
        CurrencySprite,
    },

    setup() {

        return { PopUp, STATE };
    },
};
</script>
<style lang="scss">
@import './assets/index';

.hide-sprite {
    height: 0;
    width: 0;
    position: absolute;
    visibility: hidden;
}
</style>
