
import { usePositionActions } from '@position';
import { useQuoteActions } from '@quotes';
import { STATE } from '@/Ship';

const getPositionResource = usePositionActions('getPositionResource');

const {
    getCryptoResource,
    getStockResource,
    getForexResource,
    getCommodityResource,
} = useQuoteActions();

export default function(payload) {
    const positions = getPositionResource();

    const resource = {
        crypto: getCryptoResource,
        stocks: getStockResource,
        forex: getForexResource,
        commodities: getCommodityResource,
    }[STATE.MARKET]();

    positions.create({
        ...payload,
        base: resource.model.base,
        counter: resource.model.counter,
        market: resource.model.market,
    });

    payload.usdt_amount = null;
    payload.take_profit = null;
    payload.stop_loss = null;
}