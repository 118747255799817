<script setup>
import { defineProps, defineEmits } from 'vue';
import { useWalletActions } from '@wallet';
import { useProfileActions } from '@profile';

const emit = defineEmits(['performed']);

const props = defineProps({
    wallet: {
        type: Object
    },
    amount: {
        type: Number
    },
});

const getWalletResource = useWalletActions('getWalletResource');
const wallet = getWalletResource();

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

const replenishing = () => {
    const params = {
        amount: props.amount
    };

    if (!profile.withdraw_funds) {
        params.not_withdrawal = 1;
    }

    wallet.replenishment(props.wallet.id, params).then(response => {
        const walletIndex = wallet.state.findIndex(item => item.id === props.wallet.id);
        if (walletIndex > -1) {
            Object.assign(wallet.state[walletIndex], response.data);
        }
    });
    emit('performed');
};

function closePopup() {
    emit('performed');
}
</script>

<template>
    <div>
        <div class="text">{{ $t('wallet.popupFaqSend') }}?</div>
        <div class="footer">
            <g-button icon-color="danger" @click="closePopup">
                <g-symbol name="close" width="24" height="24"/>
                {{ $t('base.cancel') }}
            </g-button>
            <g-button fill="success" @click="replenishing">
                <g-symbol name="check" width="22" height="22"/>
                {{ $t('base.replenishing') }}
            </g-button>
        </div>
    </div>
</template>

<style scoped lang="scss">
.text {
    font-size: var(--text-lg, $text-lg);
    line-height: 150%;
}

.footer {
    display: flex;
    justify-content: space-between;
    gap: 22px;
    margin-top: 32px;

    &:deep(.g-button) {
        margin: 0;
        width: 100%;

        &:first-child {
            border: 1px solid var(--main-text-color, $main-text-color);
            background-color: transparent;
            color: var(--main-text-color, $main-text-color);

            svg {
                fill: var(--danger, $danger);
            }
        }

        &:last-child {
            border: 1px solid var(--success, $success);
            color: var(--title-colo, $title-text-color);
            background-color: var(--success, $success);

            svg {
                fill: var(--title-colo, $title-text-color);
            }
        }
    }
}

@media screen and (max-width: 460px) {
    .g-popup .footer {
        flex-direction: column;
        gap: 12px;

        &:deep(.g-button) {
            margin: 0;
        }
    }
}
</style>
