
import { useAuthTasks } from '@auth';
import { STACK, ApiClient } from '@/Ship';

const {
	saveToken,
	reloadProfile
} = useAuthTasks();

export default () => {
	return STACK.push(() => ApiClient.post('/refresh', {
		refresh_token: sessionStorage.refresh_token,
	})).then(({ data }) => {
		saveToken(data);
		reloadProfile();
	});
};
