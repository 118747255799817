import { reactive } from 'vue';

const STATE = reactive({
    LOADING: false,
    IS_LOADING: false,
    IS_PROCESSING: false,
    IS_LOGIN: true,
    IS_VISIBLE: true,
    IS_LIGHT_MODE: false,
    AUTO_SAVE: true,

    FEE: 0.001,
    MIN_AMOUNT: 100,

    MARKET: 'crypto',
    SYMBOL: 'BTCUSDT',
});

export default STATE;
