import actionLoader from './core/ActionLoader';
import componentLoader from './core/ComponentLoader';
//import TaskLoader from "./core/TaskLoader";
// import popUpLoader from './core/PopUpLoader';
import resourceLoader from '@/Ship/core/ResourceLoader';
import typeLoader from '@/Ship/core/TypeLoader';

//--- Actions ---//
function useShipActions(ask = null) {
    const requireActions = require.context("./actions", true, /[\w-]+\.js$/);

    return ask
        ? actionLoader(requireActions, "@/Ship")[ask]
        : actionLoader(requireActions, "@/Ship");
}

//--- Resources ---//
function useShipResources(ask = null) {
    const requireResources = require.context('./resources', true, /[\w-]+\.js$/);

    return ask
        ? resourceLoader(requireResources)[ask].getInstance()
        : resourceLoader(requireResources)
}

//--- Tasks ---//
// function useShipTasks(ask = null) {
//     const requireTasks = require.context('./tasks', true, /[\w-]+\.js$/);
//
//     return ask
//         ? taskLoader(requireTasks)[ask]
//         : taskLoader(requireTasks);
// }

function useShipType(ask) {
    const requireTypes = require.context('./enums', true, /[\w-]+\.js$/);
    return typeLoader(requireTypes).type(ask);
}

//--- Components ---//
function useShipComponents(ask = null) {
    const requireComponents = require.context('./components', true, /[\w-]+\.vue$/);

    return ask
        ? componentLoader(requireComponents)[ask]
        : componentLoader(requireComponents);
}

const Router = require('./router').default;
const i18n = require('./setup/Localization').default;
const ApiClient = require('./setup/ApiClient').default;
const ErrorManager = require('./core/ErrorManager').default;
const PopUpManager = require('./core/PopUpManager').default;
//const AutoSaveForm = require('./core/AutoSaveForm').default;
const ValidRules = require('./core/ValidRules').default;
export const verifyField = require('./actions/verifyField').default;
const Validator = require('./core/Validator').default;
const WebSockets = require("./core/WebSockets").default;
const STATE = require('./state').default;
const RootView = require('./App').default;
const STACK = require('./core/RequestStack').default;

export {
    i18n,
    STATE,
    STACK,
    Router,
    RootView,
    ApiClient,
    ValidRules,
    ErrorManager,
    PopUpManager,
    WebSockets,
    Validator,
    useShipType,
    //AutoSaveForm,
    useShipActions,
    //useShipTasks,
    useShipResources,
    useShipComponents,
};
