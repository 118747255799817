<script setup>

import { onMounted, onUnmounted, ref } from 'vue';
import { useChartActions, useChartResources } from '@chart';

const resource = useChartResources('TradingView');

const getBarResource = useChartActions('getBarResource');
getBarResource();

const expand = ref(false);

onMounted(() => {
    resource.createWidget();
});

onUnmounted(() => {
    resource.removeWidget();
});

</script>

<template>
    <div class="chart">

        <g-flex class="expand-btn" v-bind:class="{collapsed: !expand}" v-on:click="expand = !expand" justify="between" align="center" gap="10">
            {{ $t('lead.chart', 'Chart') }}
            <hr class="separator"/>
            <g-symbol name="arrow-down" width="24" height="24"/>
        </g-flex>

        <div id="tv_chart_container" class="trading-view" v-bind:class="{expand: expand}"/>
    </div>
</template>

<style scoped>
.chart {
    height: 100%;
}

.trading-view {
    height: 100%;
}

.expand-btn {
    cursor: pointer;
    padding: 10px;
    fill: var(--main-text-color, $main-text-color);

    &:not(.collapsed) > .g-symbol {
        transform: rotate(180deg);
    }
}

.separator {
    flex-grow: 1;
    border: none;
    height: 1px;
    background-color: var(--main-text-color, $main-text-color);
}

@media (min-width: 725px) {
    .expand-btn {
        display: none !important;
    }
}

@media (max-width: 724px) {
    .trading-view {
        overflow: hidden;
        height: 500px;

        &:not(.expand) {
            display: none;
        }
    }
}
</style>