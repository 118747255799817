
import { useWithdrawResources } from '@withdraw';

export default (reload = false) => {
    const withdraw = useWithdrawResources('Withdraw');
    if (reload || (!withdraw.isLoaded && !withdraw.isLoading)) {
        withdraw.load();
    }

    return withdraw;
};
