import { useWalletResources } from '@wallet';

export default (reload =false) => {
    const wallet = useWalletResources('Wallet');
    if (reload || (!wallet.isLoaded && !wallet.isLoading)) {
        wallet.setIncludes(['coin']);
        wallet.load();
    }

    return wallet;
};
