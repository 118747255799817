
export default function(requireActions) {
    const actions = {};

    requireActions.keys().forEach(fileName => {
        const actionName = fileName.split('/').pop().replace(/\.\w+$/, '');
        actions[actionName] = requireActions(fileName).default;
    });

    return actions;
}