


import { useWalletResources } from '@wallet';

export default function(reload = false) {

    const transaction = useWalletResources('Transaction');

    if (reload || (!transaction.isLoaded && !transaction.isLoading)) {
        transaction.load();
    }

    return transaction;
}