import { useQuoteResources } from '@quotes';

export default function(reload = false) {
    const forex = useQuoteResources('Forex');
    if (reload || (!forex.isLoaded && !forex.isLoading)) {
        forex.setIncludes(['asset']);
        forex.load();
    }

    return forex;
}
