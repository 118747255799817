<script setup>

import { ref } from 'vue';
import { useAuthActions } from '@auth';
import { useProfileActions } from '@profile';
import { STATE, PopUpManager as PopUp } from '@/Ship';
import { useRouter } from 'vue-router';

const isShow = ref(false);
const router = useRouter();
const { signOut } = useAuthActions();
const languages = ref(
    process.env.VUE_APP_AVAILABLE_LOCALES.split('|')
);

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

const logout = () => {
    signOut().then(() => router.push({ name: 'SignIn' }));
};

const onChangeTheme =  (checked) => {
    const activeTheme = checked ? 'light' : 'dark';
    STATE.IS_LIGHT_MODE = checked;
    localStorage.setItem('theme', activeTheme);
    document.documentElement.setAttribute('theme', activeTheme);
};
</script>

<template>
    <div class="topbar">
        <router-link class="logo" v-bind:to="{ name: 'Trading', params: $route.params }">
            <img src="@assets/img/logo.svg" alt="LOGO"/>
        </router-link>
        <div class="balance">
            <label>Balance:</label>
            {{ profile.balance }}
        </div>

        <div style="flex-grow: 1"></div>

        <div class="toolbar" v-bind:class="{show: isShow}">

            <slot/>

            <div class="menu finance-menu">
                <g-symbol name="wallet" width="30" height="30"/>
                <router-link class="menu-item" v-bind:to="{ name: 'Wallet', params: $route.params }">{{ $t('parts.wallet') }}</router-link>
                <label class="menu-item" v-on:click="PopUp.open(require('@withdraw/components/PopUps/WithdrawForm')).setCaption($t('wallet.withdrawal', 'Withdrawal'))">{{ $t('wallet.withdrawal', 'Withdrawal') }}</label>
                <router-link class="menu-item" v-bind:to="{ name: 'History', params: $route.params }">{{ $t('parts.history') }}</router-link>
            </div>

            <div class="menu profile-menu">
                <router-link :to="{ name: 'Profile', params: { locale: $i18n.locale } }"
                             class="menu-item"
                             v-bind:class="{ active: $route.name === 'Profile' }">
                    <g-symbol name="profile" width="18" height="18"/>
                    {{ $t('parts.profile') }}
                </router-link>
                <label class="menu-item" v-on:click="logout">
                    <g-symbol name="logout" width="22" height="22"/>
                    {{ $t('base.logout') }}
                </label>
            </div>
        </div>

        <g-lang-select v-if="languages.length > 1" v-bind:lang-list="languages"/>

        <g-hamburger v-model="isShow"/>

    </div>
</template>

<style lang="scss" scoped>
.topbar {
    padding: 0 10px;
    margin: 0 auto;
    max-width: var(--env-width, $env-width);
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo {
        font-size: 20px;
        font-weight: bold;
        color: var(--title-text-color, $title-text-color);
        gap: 8px;
        display: flex;
        align-items: center;

        & > img {
            height: 56px;
        }
    }
}

.menu {
    padding: 0 20px 0 10px;

    & > .menu-item {
        cursor: pointer;
        font-size: 1.2rem;
        color: var(--main-text-color, $main-text-color);
        display: inline-flex;
        align-items: center;

        &:hover {
            color: var(--title-text-color, $title-text-color);
        }

        & > .g-symbol {
            margin-right: 5px;
            fill: var(--main-text-color, $main-text-color);
        }
    }

    & > .g-symbol {
        margin-right: 8px;
        fill: var(--main-text-color, $main-text-color);
    }
}

@media (min-width: 641px) {
    .g-hamburger {
        display: none;
    }

    .menu {
        gap: 16px;
        display: flex;
        align-items: center;

        &.finance-menu {
            border-right: 1px solid var(--separator-color, $separator-color);
        }
    }

    .toolbar {
        gap: 10px;
        display: flex;
        align-items: center;
    }
}

@media (max-width: 640px) {
    .g-hamburger {
        z-index: 10;
    }

    .menu {
        padding: 20px 10px;

        &:first-child {
            border-bottom: 1px solid var(--separator-color, $separator-color);
        }

        &.finance-menu > .g-symbol {
            display: none;
        }

        & > .menu-item {
            display: block;
            margin: 10px 0;
        }
    }

    .toolbar {
        z-index: 4;
        right: 0;
        position: fixed;
        top: 0;
        background-color: var(--bar-bg, $bar-bg);
        width: 240px;
        max-width: 100%;
        height: 100%;
        border-radius: 5px;
        padding: var(--bar-height, $bar-height) 10px;
        transition: max-width 0.5s, padding 0.5s 0.1s;

        &:not(.show) {
            max-width: 0;
            padding-left: 0;
            padding-right: 0;
        }
    }
}

@media (min-width: 480px) {
    .balance {
        display: none;
    }
}

@media (max-width: 480px) {
    .balance {
        padding-left: 10px;
        color: var(--title-text-color, $title-text-color);
        font-family: var(--title-font, $font-title);
        border-left: 1px solid var(--separator-color, $separator-color);

        & > label {
            display: block;
            font-size: 12px;
            color: var(--main-text-color, $main-text-color);
            font-family: var(--primary-font, $font-primary);
        }
    }
}

.connect {
    fill: var(--success, $success);
    transition: fill 0.5s ease-out 0.2s;
}

.disconnect {
    fill: var(--danger, $danger);
    transition: fill 0.5s ease-out 0.2s;
}
</style>
