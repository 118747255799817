<script>

import { useCoverComponents } from '@overlay';
import { useAuthComponents } from '@auth';

export default {
    name: 'Authentication',

    extends: useCoverComponents('Layout'),

    components: {
        ...useAuthComponents(),
    }
};
</script>