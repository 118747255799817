<template>
    <div class="g-preloadr-bar loader"></div>
</template>

<script>
export default {
    name: "gPreloadrBar"
}
</script>

<style lang="scss" scoped>
.loader {
    top: 50%;
    width: 100%;
    height: 5px;
    overflow: hidden;
    position: absolute;
    transform: translateY(-50);
}

.loader::after {
    content: '';
    top: 0;
    left: 0;
    width: 96px;
    height: 100%;
    max-width: 50%;
    position: absolute;
    background: #ffffff;
    border-radius: 3px;
    animation: hit-zak 0.4s ease-in-out infinite alternate;
}

@keyframes hit-zak {
    0% {
        left: 0;
        transform: translateX(-1%);
    }

    100% {
        left: 100%;
        transform: translateX(-99%);
    }
}

</style>