
const allChart = {
    upperLetter: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
    lowerLetter: 'abcdefghijklmnopqrstuvwxyz',
    numbers: '0123456789',
    symbols: '?^/~*&@%#$+!',
};

const allChartString = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789?^/~*&@%#$+!';

export default (length = 8) => {
    length = length < 4
        ? 4
        : length;

    const requireCharts = Object.values(allChart).map(function (charts) {
        return _.sample(charts.split(''));
    });

    const elseRandom = [...Array(length - requireCharts.length)].map(() =>
        _.sample(allChartString.split(''))
    );

    return _.shuffle([...requireCharts, ...elseRandom]).join('');
};
