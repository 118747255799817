
export default [{
    name: 'Wallet',
    path: ``,
    component: () => import('./Dashboard.vue'),
}, {
    name: 'Transactions',
    path: `transactions`,
    component: () => import('./Transactions.vue'),
}
];
