
import actionLoader from '@/Ship/core/ActionLoader';
//import TaskLoader from "@/Ship/core/TaskLoader";
import resourceLoader from '@/Ship/core/ResourceLoader';
import componentLoader from '@/Ship/core/ComponentLoader';

//--- Actions ---//
export function usePositionActions(ask = null) {
    const requireActions = require.context('./actions', true, /[\w-]+\.js$/);

    return ask
        ? actionLoader(requireActions)[ask]
        : actionLoader(requireActions);
}

//--- Tasks ---//
// export function usePositionTasks(ask = null) {
//     const requireTasks = require.context('./tasks', true, /[\w-]+\.js$/);
//
//     return ask
//         ? taskLoader(requireTasks)[ask]
//         : taskLoader(requireTasks);
// }

//--- Resources ---//
export function usePositionResources(ask = null) {
    const requireResources = require.context('./resources', true, /[\w-]+\.js$/);

    return ask
        ? resourceLoader(requireResources)[ask] && resourceLoader(requireResources)[ask].getInstance()
        : resourceLoader(requireResources);
}

//--- Components ---//
export function usePositionComponents(ask = null) {

    const requireComponents = require.context('./components', true, /[\w-]+\.vue$/);
    return ask
        ? componentLoader(requireComponents)[ask]
        : componentLoader(requireComponents);
}

export default require('./View').default;
