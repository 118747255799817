<template>
    <div class="tabbar">
        <span v-on:click="inputEvent" v-for="(tab, key) in tabs" :key="key">{{ tab }}</span>
    </div>
    <div>
        <slot/>
    </div>
</template>

<script>
export default {
    name: 'GTabbar',
    emits: ['update:modelValue'],
    props: {
        tabs: {
            type: Array,
        },
        modelValue: { type: String },
    },
    setup(props, { emit }) {
        const inputEvent = event => emit('update:modelValue', event.target.textContent);

        return {
            inputEvent,
        };
    }
};
</script>

<style scoped>

</style>