import { STATE } from '@/Ship';
import { useAuthTasks } from '@auth';

export default {
    apiErrors: {
        401: error => {
            STATE.IS_LOGIN = false;
            const { deleteToken } = useAuthTasks();
            deleteToken();
            console.error(`ERROR: ${error.message}`);
        },

        422: error => {
            console.error(`ERROR: ${error.message}`);
        },

        500: error => {
            console.error(`ERROR: ${error.message}`);
        },

        default: {}
    },

    webSocketErrors: {
        default: {}
    }

};
