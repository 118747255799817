
export default function(bar) {

    return {
        time: bar.openTime,
        open: bar.open,
        high: bar.high,
        low: bar.low,
        close: bar.close,
    };
}