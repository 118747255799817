<template>
    <form class="profile-form" v-on:submit.prevent="updateProfile" v-on:input="isAllow = true">
        <g-field v-model="firstName"
                 v-bind:label="$t('profile.first_name')"
                 v-bind:error="errors.first_name"/>

        <g-field type="text"
                 v-bind:label="$t('profile.last_name')"
                 v-model="lastName"
                 v-bind:error="errors.last_name"/>

        <g-field v-model="email"
                 type="email"
                 v-bind:label="$t('profile.email')"
                 v-bind:error="errors.email"
                 readonly>
            <g-symbol name="lock" width="18" height="18"/>
        </g-field>

        <!--
        <g-phone-input v-if="countries.state.length"
                       v-model="phone"
                       v-model:country="profile.country_code"
                       v-bind:countries="countries.state"
                       v-bind:label="$t('profile.phone')"
                       v-bind:error="errors.phone"/>
        -->

        <fieldset class="password-set">
            <legend>Password</legend>

            <!--
                <g-field v-model="oldPassword"
                         v-bind:label="$t('profile.old_password')"
                         v-bind:type="showOldPassword ? 'text' : 'password'"
                         autocomplete="new-password">
                    <g-symbol v-bind:name="showOldPassword ? 'eye-open' : 'eye-close'"
                              v-on:click="showOldPassword = !showOldPassword"
                              width="20"
                              height="20"/>
                </g-field>
            -->

            <password-input v-model="password"
                            v-bind:complex="isComplex"
                            v-bind:label="$t('profile.new_password')"
                            v-bind:error="errors.password"/>
        </fieldset>

        <g-flex class="btn-bar" justify="end">
            <g-button class="save-btn"
                      type="submit"
                      v-bind:disabled="!isAllow">
                {{ $t('base.save') }}
            </g-button>
        </g-flex>
    </form>
</template>

<script>

import { computed, ref, reactive, watch } from 'vue';
import { verifyField } from '@/Ship';
import { useProfileActions } from '@profile';
import { useAuthComponents } from '@auth';

const {
    getProfileResource,
    //getCountryResource,
} = useProfileActions();

export default {
    name: 'Info',
    components: {
        PasswordInput: useAuthComponents('PasswordInput'),
    },

    setup: function () {
        const profile = getProfileResource();
        //const countries = getCountryResource();

        const isAllow = ref(false);
        const isComplex = ref(false);
        const errors = reactive({
            first_name: null,
            last_name: null,
            //email: null,
            //phone: null,
            password: null,
        });

        const firstName = computed({
            get: () => profile.first_name,
            set: value => {
                profile.first_name = value;
                errors.first_name = verifyField(value, ['require', 'minLength:2']);
                return true;
            }
        });

        const lastName = computed({
            get: () => profile.last_name,
            set: value => {
                profile.last_name = value;
                errors.last_name = verifyField(value, ['require', 'minLength:2']);
                return true;
            }
        });

        // const email = computed(() => {
        //     return profile.email.replace(/^(.+)@(.+)$/, (match, p1, p2) => {
        //         return p1.replace(/\w/g, '*') + '@' + p2;
        //     });
        // });

        const email = computed({
            get: () => profile.email.replace(/^(.+)@(.+)$/, (match, p1, p2) => {
                return p1.replace(/\w/g, '*') + '@' + p2;
            }),
            set: value => {
                profile.email = value;
                errors.email = verifyField(value, ['require', 'email']);
                return true;
            }
        });

        // const phone = computed({
        //     get: () => profile.phone,
        //     set: value => {
        //         profile.phone = value;
        //         errors.phone = verifyField(value, ['require', 'phone']);
        //         return true;
        //     }
        // });

        const password = computed({
            get: () => profile.password,
            set: value => {
                profile.password = value;
                errors.password = verifyField(value, ['minLength:8']);
                return true;
            }
        });

        const updateProfile = () => {
            if (profile.isValidationPassed()) {
                profile.save();
                profile.clearEntity();
            }
        };

        watch([errors, () => profile.password], () => {
            isAllow.value = true;
            for (const key in errors) {
                if (errors[key] !== null) {
                    isAllow.value = false;
                    break;
                }
            }
        });

        return {
            profile,
            firstName,
            lastName,
            email,
            //phone,
            //countries,
            password,
            errors,
            isAllow,
            isComplex,
            updateProfile,
        };
    }
};
</script>

<style lang="scss" scoped>
.profile-form {
    padding: 30px;
    border-radius: var(--bar-rounding, $bar-rounding);
    border: 1px solid var(--separator-color, $separator-color);

    .g-field,
    .password-input,
    .g-phone-input {
        margin-top: 16px;
    }
}

.password-set {
    margin-top: 25px;
    border-top: 1px solid var(--field-border, $field-border);
}

.btn-bar {
    margin-top: 20px;
}

.save-btn {
    padding: 0 20px;
    background-color: var(--primary, $primary);

    &.disabled {
        opacity: 0.4;
        filter: grayscale(1);
        pointer-events: none;
    }
}
</style>
