
import { useShipResources } from '@/Ship';
const { BaseResource } = useShipResources();

export default class Coin extends BaseResource {
    static #instance = null;
    endpoint = `${process.env.VUE_APP_WALLET_SERVER}/client/coins`;


    static getInstance() {
        if (!Coin.#instance) {
            Coin.#instance = new Coin();
        }
        return Coin.#instance;
    }
}
