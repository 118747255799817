import { createApp } from 'vue';
import VueAxios from 'vue-axios';
import Vue3TouchEvents from 'vue3-touch-events';
import { SetupCalendar, Calendar, DatePicker } from 'v-calendar';


import 'v-calendar/dist/style.css';
import '@assets/css/normalize.css';
import('@assets/themes/x-trade.css');
//import('@assets/themes/light.css');

import {
    i18n,
    STATE,
    Router,
    RootView,
    ApiClient,
    useShipActions,
} from './Ship';

const app = createApp(RootView)
    .use(Router)
    .use(i18n)
    .use(Vue3TouchEvents)
    .use(VueAxios, ApiClient)
    .use(SetupCalendar)
    .component('Calendar', Calendar)
    .component('DatePicker', DatePicker);


STATE.IS_LOGIN = ApiClient.checkToken();
STATE.IS_LIGHT_MODE = localStorage.getItem('theme') === 'light';

import { useProfileResources } from '@profile';
const profile = useProfileResources('Profile');

const profileParams = (!!sessionStorage.login_type)
    ? { login_type: sessionStorage.login_type }
    : {};


(async function() {
    const theme = localStorage.getItem('theme') || await ApiClient.get('/').then(response => {
        localStorage.setItem('theme', response.data.brand.theme);
        return response.data.brand.theme;
    });

    document.documentElement.setAttribute('theme', theme);
})();


profile.load(profileParams).finally(() => {

    i18n.selectLocale(i18n.global.locale);

    const {
        getGlobalComponents
    } = useShipActions();

    sessionStorage.removeItem('login_type');

    /*~~~~~ Registering global components ~~~~~*/

    const componentList = getGlobalComponents();
    for (let componentName in componentList) {
        app.component(componentName, componentList[componentName]);
    }

    /*~~~~~ Visibility Observer ~~~~~*/
    document.addEventListener('visibilitychange', function () {
        setTimeout(() => {
            STATE.IS_VISIBLE = document.visibilityState === 'visible';
        }, 2000);
    });

    app.mount('#app');
});
