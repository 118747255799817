import { useShipResources} from '@/Ship';

const { BaseResource } = useShipResources();

export default class Deposit extends BaseResource {

    static #instance = null;

    endpoint = 'deposits';

    section = 'Finances';
    container = 'Deposit';

    constructor() {
        super();
        if (!Deposit.#instance) {
            Deposit.#instance = this;
        }

        return Deposit.#instance;
    }

    static getInstance() {
        return new Deposit();
    }
}
