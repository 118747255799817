<script setup>
import { computed, ref, reactive, defineEmits } from 'vue';
import { useShipActions } from '@/Ship';
import { useProfileActions } from '@profile';
import { useWalletActions } from '@wallet';
import { useWithdrawActions } from '@withdraw';

const emit = defineEmits(['performed']);

const onlyNumberInput = useShipActions('onlyNumberInput');

const isLock = ref(true);
const showErrorAlert = ref(false);
const index = ref();
const wallet = ref();
const payload = reactive({
    method: 'crypto',
    type: 'Internal',
    amount: 0,
    currency: 'BTC',
    address: '',
    reason: '',
});

const cardDetails = reactive({
    card_name: '',
    card_number: '',
});

const amount = computed({
    get() {
        return payload.amount;
    },
    set(value) {
        payload.amount = value;
        isLock.value = !(value > 0 && value <= profile.balance);
    }
});

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

const getWithdrawResource = useWithdrawActions('getWithdrawResource');
const withdraws = getWithdrawResource();

const {
    getCoinResource,
    getWalletResource
} = useWalletActions();
const coins = getCoinResource();
const wallets = getWalletResource();


function setMethod(tab) {
    payload.method = tab;
    payload.currency = tab === 'crypto' ? 'BTC' : 'USD';
}

function sendTransaction() {

    isLock.value = true;
    showErrorAlert.value = false;

    payload.details = {};
    if (payload.method === 'card') {
        Object.assign(payload.details, cardDetails);
    } else if (payload.type === 'Internal') {
        payload.currency = wallets.state[index.value].coin.symbol;
        payload.address = wallets.state[index.value].address;
    }

    withdraws.create(payload)
        .then(() => {
            emit('performed');
        })
        .catch(() => {
            isLock.value = false;
            showErrorAlert.value = true;
        });
}
</script>

<template>
    <form class="withdrawal" v-on:submit.prevent="sendTransaction">

        <div v-if="showErrorAlert" class="alert">
            <g-caption size="5" align="center">{{ $t('finances.order_not_send_alert', 'The order was not sent') }}.</g-caption>
            <g-caption size="4" align="center">{{ $t('base.try_again', 'Try again') }}</g-caption>
        </div>

        <g-caption v-else class="caption" size="4" align="center">Choose withdrawal method</g-caption>

        <g-tabs v-model="payload.method" v-bind:tab-list="{crypto: 'Crypto Currency', card: 'Card Payment'}"/>

        <hr class="separator"/>

        <g-flex class="balance" align="center">
            {{ $t('profile.balance', 'Balance') }}:
            <g-symbol width="22" height="22" name="tether"/>
            <b>{{ profile.balance }}</b>
        </g-flex>

        <hr class="separator"/>

        <fieldset>
            <legend>{{ $t('wallet.amount', 'Amount') }}</legend>
            <g-flex class="amount-bar" align="stretch" gap="5">
                <g-input v-model="amount"
                         v-bind:label="$t('wallet.amount', 'Amount')"
                         step="any"
                         type="number"
                         required>
                    <g-symbol width="32" height="32" name="tether"/>
                </g-input>
                <button type="button" class="max-btn btn" v-on:click="amount = profile.balance">max</button>
            </g-flex>
        </fieldset>

        <div v-if="payload.method === 'crypto'" class="crypto">
            <fieldset class="wallet-type">
                <legend>{{ $t('wallet.wallet_type', 'Wallet Type') }}:</legend>

                <label><input type="radio" v-model="payload.type" name="wallet_type" value="Internal"> <span>{{ $t('wallet.internal', 'Internal') }}</span></label>
                <label><input type="radio" v-model="payload.type" name="wallet_type" value="External"> <span>{{ $t('wallet.external', 'External') }}</span></label>
            </fieldset>

            <fieldset v-if="payload.type === 'Internal'">
                <g-select v-model="wallet"
                          v-model:index="index"
                          v-bind:options="wallets.state.map((item => {return {value: item.coin.name}}))"
                          option-value="value"
                          option-text="value"
                          v-bind:label="$t('wallet.wallet', 'Wallet')"/>
            </fieldset>
            <fieldset v-else>
                <g-flex gap="10" align="center">
                    <g-field v-model="payload.address"
                             v-bind:label="$t('wallet.address', 'Address')"/>

                    <g-fade-container icon="wallet"
                                      v-bind:label="payload.currency">
                        <label v-for="coin in coins.state" class="currency">
                            <input type="radio" v-model="payload.currency" name="currency" v-bind:value="coin.symbol">
                            <span> <g-symbol-currency v-bind:name="coin.symbol.toLowerCase()" width="20" height="20"/> {{ coin.name }}</span>
                        </label>
                    </g-fade-container>
                </g-flex>
            </fieldset>
        </div>

        <div v-else-if="payload.method === 'card'" class="card">
            <fieldset>
                <legend>{{ $t('finances.card_name', 'Name on Card') }}</legend>
                <g-input v-model="cardDetails.card_name"/>
            </fieldset>
            <fieldset>
                <legend>{{ $t('finances.card_number', 'Card number') }}</legend>
                <g-input v-model="cardDetails.card_number"
                         placeholder="0000 0000 0000 0000"
                         v-on:keypress="onlyNumberInput($event, ' ')"/>
            </fieldset>
        </div>

        <fieldset>
            <legend>{{ $t('base.reference', 'Reference') }}</legend>
            <g-textarea v-model="payload.reason" label="..."/>
        </fieldset>

        <g-flex justify="end" gap="5">
            <g-button class="cancel-btn" type="button" v-on:click="$emit('performed')">{{ $t('base.cancel', 'Cancel') }}</g-button>
            <g-button class="send-btn"
                      type="submit"
                      v-bind:disabled="isLock">{{ $t('base.send', 'Send') }}</g-button>
        </g-flex>
    </form>
</template>

<style scoped lang="scss">
.withdrawal {
    width: 340px;
    max-width: 100%;
}

.caption {
    margin-top: -20px;
}

.alert {
    padding: 10px;
    border: 1px solid var(--danger, $danger);
}

.method {
    cursor: pointer;
    padding: 10px 15px;
    gap: 5px;
    display: inline-flex;
    align-items: center;
    fill: var(--main-text-color, $main-text-color);
    //border: 1px solid var(--main-text-color, $main-text-color);
    //border-radius: var(--btn-rounding, $btn-rounding);

    &:hover {
        fill: var(--title-text-color, $title-text-color);
        color: var(--title-text-color, $title-text-color);
    }

    &.active {
        fill: var(--title-text-color, $title-text-color);
        color: var(--title-text-color, $title-text-color);
        background-color: var(--bar-bg, $bar-bg);
        border-color: var(--bar-bg, $bar-bg);
    }
}

.separator {
    border: none;
    height: 1px;
    background-color: var(--separator-color, $separator-color);
}

fieldset {
    margin: 15px 0;

    & > legend {
        margin-left: 5px;
    }
}

.wallet-type > label {
    display: inline-block;
    cursor: pointer;
    line-height: 22px;
    margin-right: 10px;

    & > span::before {
        vertical-align: middle;
        display: inline-block;
        content: '';
        width: 12px;
        height: 12px;
        margin-right: 5px;
        border-radius: 6px;
        border: 1px solid var(--separator-color, $separator-color);
    }

    & > input:checked + span::before {
        background-color: var(--success-hover, $success-hover);
    }

    &:hover {
        color: var(--title-text-color, $title-text-color);
    }
}

.balance > .g-symbol {
    margin-left: 5px;
    fill: var(--main-text-color, $main-text-color);
}

.amount-bar {
    &:deep(.g-input) {
        flex-grow: 1;
    }
}

.max-btn {
    width: 40px;
    cursor: pointer;
    font-size: 13px;
    background-color: transparent;
    color: var(--main-text-color, $main-text-color);
    border: 1px solid var(--success, $success);
    border-radius: 5px;

    &:hover {
        color: var(--title-text-color, $title-text-color);
        background-color: var(--success, $success);
    }
}

.cancel-btn {
    min-width: 100px;
    background-color: transparent;
    color: var(--main-text-color, $main-text-color);
    border: 1px solid var(--login-btn, $login-btn);

    &:hover {
        color: var(--title-text-color, $title-text-color);
        border-color: var(--login-btn-hover, $login-btn-hover);
    }
}

.send-btn {
    min-width: 100px;
    background-color: var(--login-btn, $login-btn);
    color: var(--title-text-color, $title-text-color);
    border: 1px solid var(--login-btn, $login-btn);

    &:hover {
        background-color: var(--login-btn-hover, $login-btn-hover);
    }
}

.g-fade-container {
    width: 134px;

    &:deep(button) {
        width: 100%;

        &:not(.active) {
            background-color: transparent;
            color: var(--main-text-color, $main-text-color);
        }
    }

    &:deep(.container) {
        background-color: var(--bar-bg, $env-bg);

        & > .currency {
            cursor: pointer;
            white-space: nowrap;
            color: var(--main-text-color, $main-text-color);

            & > span {
                gap: 10px;
                display: flex;
                align-items: center;
            }

            &:hover {
                color: var(--title-text-color, $title-text-color);
            }
        }
    }
}
</style>