
import { useOrderBookActions } from '@order-book';

const makeOrder = useOrderBookActions('makeOrder');

export default function(price, trend, count = 10) {
	
    const precision= Number(price).toString().split('.');
    const whole = precision[0].length;
    const fraction = precision[1]?.length || 2;
	
    const factor = fraction > 2
        ? whole > 2 ? 0.01 : 0.2
        : whole > 2 ? 0.01 : 0.2;
	
    const orders = [];
    let order = { price };
    while(orders.length < count) {
		
        if (trend === 'buy') {
            const min = order.price - ((order.price * factor)  / 100);
            order = makeOrder(min, order.price, fraction);
        } else {
            const max = Number(order.price) + ((order.price * factor) / 100);
            order = makeOrder(order.price, max, fraction);
        }
		
        trend === 'buy'
            ? orders.unshift(order)
            : orders.push(order);
    }
	
    return orders;
}