
export default function(requireTasks) {
    const tasks = {};

    requireTasks.keys().forEach(fileName => {
        const taskName = fileName.split('/').pop().replace(/\.\w+$/, '');
        tasks[taskName] = requireTasks(fileName).default;
    });

    return tasks;
}