
import { STATE, useShipActions } from '@/Ship';
import { useQuoteActions } from '@quotes';

const {
    getCryptoResource,
    getStockResource,
    getForexResource,
    getCommodityResource,
} = useQuoteActions();

const definePriceScale = useShipActions('definePriceScale');

export default function (symbolName, onSymbolResolvedCallback) {

    const resource = {
        crypto: getCryptoResource,
        stocks: getStockResource,
        forex: getForexResource,
        commodities: getCommodityResource,
    }[STATE.MARKET]();

    setTimeout(function() {
        onSymbolResolvedCallback({
            symbol: resource.model.base,
            name: `${resource.model.base}:${(resource.model.counter || 'USDT')}`,//resource.symbol,
            description: resource.model.base,
            market: resource.model.market,
            minmov: 1,
            pricescale: definePriceScale(resource.state[resource.index]?.price),
            currency_code: resource.model.counter || 'USDT',
            session: '24x7',
            timezone: 'Etc/UTC',
            exchange: resource.model.asset.name,
            has_intraday: true,
            has_daily: true,
            has_weekly_and_monthly: true,
            supported_resolutions: [
                '1',  '5', '15', '30', '60', '1D', '1W',
            ],
            volume_precision: 2,
            data_status: 'streaming',
        });
    }, 0);
}