import { useShipResources} from '@/Ship';

const { BaseResource } = useShipResources();

export default class Withdraw extends BaseResource {

    static #instance = null;

    endpoint = 'withdraws';

    section = 'Finances';
    container = 'Withdraw';

    constructor() {
        super();
        if (!Withdraw.#instance) {
            Withdraw.#instance = this;
        }

        return Withdraw.#instance;
    }

    static getInstance() {
        return new Withdraw();
    }
}
