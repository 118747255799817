export default [
    {
        name: 'SignIn',
        path: `/:locale(${process.env.VUE_APP_AVAILABLE_LOCALES})?/sign-in`,
        component: () => import('./View'),
    },
    {
        name: 'Impersonate',
        path: `/:locale(${process.env.VUE_APP_AVAILABLE_LOCALES})?/impersonate`,
        component: () => import('./View'),
    },
    {
        name: 'Autologin',
        path: `/:locale(${process.env.VUE_APP_AVAILABLE_LOCALES})?/autologin`,
        component: () => import('./View'),
    },
    {
        name: 'SignUp',
        path: `/:locale(${process.env.VUE_APP_AVAILABLE_LOCALES})?/sign-up`,
        component: () => import('./View'),
    },
    {
        name: 'ResetPassword',
        path: `/:locale(${process.env.VUE_APP_AVAILABLE_LOCALES})?/reset-password`,
        component: () => import('./View'),
    },
];
