export default function (requireTypes) {
    return {
        type(ask) {
            const type = requireTypes.keys().find(fileName => {
                return new RegExp(`${ask}.enum.js`).test(fileName);
            });
            const typeConfig = requireTypes(type);

            return typeConfig.default || typeConfig;
        }
    };
}