<template>
    <form v-on:submit.prevent="sendTransaction">

        <fieldset>
            <legend>{{ $t('wallet.from_address', 'From Address') }}</legend>
            <g-input :value="wallet.model.address" readonly/>
        </fieldset>

        <fieldset>
            <legend>{{ $t('wallet.to_address', 'To Address') }}</legend>
            <g-input v-model="payload.toAddress" required/>
        </fieldset>

        <!--
        <div>
            <div class="title">{{ $t('wallet.fee', 'Fee') }}</div>
            <g-select v-model="payload.fee"
                      v-bind:options="feeOptions"
                      option-text="label"
                      option-value="value"
                      size="large"/>
        </div>
        -->

        <div class="last-row">

            <fieldset>
                <legend>{{ $t('wallet.amount', 'Amount') }}</legend>
                <g-input v-model="payload.amount"
                         v-bind:label="$t('wallet.amount', 'Amount')"
                         step="any"
                         type="number"
                         required>
                    <g-symbol-currency width="22" height="22" v-bind:name="wallet.model.coin.symbol.toLowerCase()"/>
                    <span class="max-btn btn" v-on:click="maxAmount">max</span>
                </g-input>
            </fieldset>

            <g-button v-bind:disabled="!isAllow">{{ $t('base.send', 'Send') }}</g-button>
        </div>
    </form>
</template>

<script>
import { reactive, computed } from 'vue';
import { i18n, PopUpManager as PopUp } from '@/Ship';
import { useWalletResources } from '@wallet';

export default {
    name: 'Withdraw',

    setup() {
        const wallet = useWalletResources('Wallet');
        const payload = reactive({
            toAddress: '',
            amount: '',
            fee: '',
        });
        const feeOptions = [
            { value: '0.02', label: 'Fee 1', id: 1 },
            { value: '0.01', label: 'Fee 2', id: 2 },
            { value: '0.005', label: 'Fee 3', id: 3 },
        ];
        const isAllow = computed(() => {
            return payload.amount <= Number(wallet.model.balance) && payload.amount > 0;
        });

        function sendTransaction() {
            PopUp.open(require('@wallet/components/parts/SendDetail'))
            .setCaption(i18n.global.getLocaleMessage(i18n.global.locale.value).popupTitle.send_details)
            .setPayload({
                payload: {
                    ...payload,
                    walletId: wallet.model.id,
                    address: wallet.model.address,
                    currency: wallet.model.coin.symbol,
                    name: wallet.model.name,
                    type: wallet.model.type,
                },
            });
        }

        function maxAmount() {
            payload.amount = Number(wallet.model.balance);
        }

        return {
            wallet,
            payload,
            isAllow,
            feeOptions,
            sendTransaction,
            maxAmount,
        };
    },
};
</script>

<style lang="scss" scoped>
form {
    width: 380px;
    max-width: 100%;
    padding-bottom: 20px;
    container-type: inline-size;
}

fieldset {
    margin-top: 20px;
    min-width: 60%;
}

.g-symbol-currency {
    flex-shrink: 0;
    margin-right: 10px !important;
}

.max-btn {
    cursor: pointer;
    font-size: 13px;
    margin-right: 8px;
    line-height: 1;
    padding: 2px 3px 4px 3px;
    background-color: transparent;
    color: var(--warning, $warning);
    border: 1px solid var(--warning, $warning);
    border-radius: 5px;

    &:hover {
        color: var(--title-text-color, $title-text-color);
        border-color: var(--title-text-color, $title-text-color);
    }
}

.g-button {
    width: 100%;
    margin-top: 8px;
    background-color: var(--login-btn, $login-btn);

    &:hover {
        background-color: var(--login-btn-hover, $login-btn-hover);
    }
}

@container (min-width: 350px) {
    .last-row {
        gap: 10px;
        display: flex;
        align-items: end;
    }
}
</style>