
import { useShipResources } from '@/Ship';
const { BaseResource } = useShipResources();


export default class History extends BaseResource {

    static #instance = null;

    endpoint = 'position-history';

    section = 'Trading';
    container = 'Position';

    constructor() {
        super();
        if (!History.#instance) {
            History.#instance = this;
        }

        return History.#instance;
    }

    static getInstance() {
        return new History();
    }
}
