import {camelCase, upperFirst} from "lodash";

export default function(requireComponents) {
    let components = {};
    requireComponents.keys().forEach(fileName => {
        const componentConfig = requireComponents(fileName);
        const componentName = upperFirst(
            camelCase(fileName.split('/').pop().replace(/\.\w+$/, ''))
        );
        components[componentName] = componentConfig.default || componentConfig;
    });

    return components;
}