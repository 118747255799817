
import { useOrderBookResources } from '@order-book';

export default function(reload = false) {

    const trade = useOrderBookResources('Trade');

    // if (reload || (!trade.isLoaded && !trade.isLoading)) {
    //     trade.load({
    //         market: ['crypto'],
    //         base: ['BTC'],
    //         counter: ['USDT'],
    //     });
    // }

    return trade;
}