
import { useQuoteResources } from '@quotes';

export default function(reload = false) {
    const commodity = useQuoteResources('Commodity');
    if (reload || (!commodity.isLoaded && !commodity.isLoading)) {
        commodity.load().then(response => {
            if (response.length) {
                commodity.choose(commodity.symbol);
            }
        });
    }

    return commodity;
}
