<template>
    <div class="g-flex" :class="[{'wrap': wrap}, {'inline': inline}, {[`direction-${direction}`]: direction}, {[`align-items-${align}`]: align}, {[`justify-content-${justify}`]: justify}]">
        <slot/>
    </div>
</template>

<script>
import { computed, toRefs } from 'vue';

export default {
    name: 'GFlex',
    props: {
        wrap: {
            type: Boolean,
            default: false
        },
        inline: {
            type: Boolean,
            default: false
        },
        direction: {
            type: String
        },
        align: {
            type: String
        },
        justify: {
            type: String
        },
        gap: {
            type: String,
            default: '0'
        },
    },
    setup(props) {
        const { gap } = toRefs(props);
        const flexGap = computed(() => {
            return gap.value + 'px';
        });

        return {
            flexGap
        };
    }
};
</script>

<style lang="scss" scoped>
.g-flex {
    gap: v-bind(flexGap);

    &:not(.inline) {
        display: flex;
    }
}

.inline {
    display: inline-flex;
}

.wrap {
    flex-wrap: wrap;
}

.direction {
    &-row {
        flex-direction: row;
    }

    &-column {
        flex-direction: column;
    }

    &-row-reverse {
        flex-direction: row-reverse;
    }

    &-column-reverse {
        flex-direction: column-reverse;
    }
}

.justify-content {
    &-start {
        justify-content: flex-start;
    }

    &-end {
        justify-content: flex-end;
    }

    &-center {
        justify-content: center;
    }

    &-between {
        justify-content: space-between;
    }

    &-around {
        justify-content: space-around;
    }

    &-evenly {
        justify-content: space-evenly;
    }
}

.align-items {
    &-start {
        align-items: flex-start;
    }

    &-end {
        align-items: flex-end;
    }

    &-center {
        align-items: center;
    }

    &-baseline {
        align-items: baseline;
    }

    &-stretch {
        align-items: stretch;
    }
}
</style>