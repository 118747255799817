<template>
    <div class="g-select" v-bind:class="[{ disabled: disabled }]">

        <fieldset class="field" v-bind:class="{filled: !!modelValue, focus: isFocus}">
            <input v-model="selected"
                   v-bind="$attrs"
                   v-bind:value="selected"
                   v-on:focus="showList"
                   v-on:blur="hideList"
                   placeholder=" "
                   readonly>

            <legend>{{ label }}</legend>
            <g-symbol v-if="disabled" class="arrow" name="lock" width="20" height="20"/>
            <g-symbol v-else class="arrow" name="arrow-down" width="20" height="20"/>

            <div ref="datalist"
                 class="datalist"
                 v-bind:class="{ 'to-top': toTop }">
                <label v-for="(option, idx) in options"
                       v-bind:key="option && option[optionValue]"
                       v-bind:class="{active: option && option[optionValue] === modelValue}">
                    <input v-model="index"
                           :class="textTransform"
                           type="radio"
                           v-bind:value="idx"
                           v-on:change="processing(idx)"
                           hidden>
                    {{ option && option[optionText] }}
                </label>
            </div>
        </fieldset>
    </div>
</template>

<script>

import { ref, watchEffect } from 'vue';

export default {
    name: 'GSelect',
    props: {
        modelValue: {},
        index: {
            type: Number,
        },
        options: {
            type: Object,
            default: () => {}
        },
        optionText: {
            Type: [String, Object],
        },
        optionValue: {
            Type: [String, Object],
        },
        label: {
            type: String
        },
        textTransform: {
            type: String
        },
        disabled: {
            type: Boolean,
            default: false
        },
    },
    emits: ['update:modelValue', 'update:index', 'select'],

    setup(props, { emit }) {

        const isFocus = ref(false);
        const toTop = ref(false);
        const datalist = ref(null);
        const selected = ref('');

        const showList = () => {
            isFocus.value = true;
            const { y } = datalist.value.getBoundingClientRect();
            toTop.value = (y + 300) > screen.availHeight;
        };
        const hideList = () => {
            setTimeout(() => {
                isFocus.value = false;
            }, 200);
        };

        const processing = (index) => {
            //selected.value = props.options[index][props.optionText];
            emit('update:index', index);
            emit('update:modelValue', props.options[index][props.optionValue]);
            emit('select', props.options[index][props.optionValue]);
        };

        watchEffect(() => {
            const value = props.options.find(item => item && item[props.optionValue] === props.modelValue);
            if (value) {
                selected.value = value[props.optionText];
            } else {
                selected.value = '';
            }
        });

        return {
            toTop,
            isFocus,
            datalist,
            selected,
            showList,
            hideList,
            processing,
        };
    }
};
</script>

<style lang="scss" scoped>
.field {
    position: relative;
    padding: 0 10px 0 0;
    display: flex;
    white-space: nowrap;
    align-items: center;
    box-sizing: border-box;
    color: var(--main-text-color, $main-text-color);
    fill: var(--input-label-color, $label-color);
    border: 1px solid var(--input-border, $input-border);
    border-radius: var(--input-rounding, $input-rounding);
    //background-color: var(--select-active-bg, $select-active-bg);

    legend {
        position: absolute;
        margin-left: 8px;
        padding: 0 2px;
        max-width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        pointer-events: none;
        color: var(--label-color, $label-color);
        transition: all 0.3s;
        z-index: 1;
    }

    input {
        flex-grow: 1;
        width: 100%;
        height: 100%;
        border: none;
        padding-left: 10px;
        box-sizing: border-box;
        background-color: transparent;
        color: var(--title-text-color, $title-text-color);
        font-size: var(--input-font-size, $input-font-size);
        font-family: var(--font-primary, $font-primary);

        &::placeholder {
            color: transparent;
        }

        &:focus {
            outline: none;
        }

        &:focus ~ .arrow {
            transform: rotate(180deg);
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
            background: transparent;
            transition: background-color 5000s ease-in-out 0s;
            -webkit-text-fill-color: var(--main-text-color, $main-text-color);
        }
    }

    & > .arrow {
        flex-shrink: 0;
        transition: transform 0.2s;
        fill: var(--main-text-color, $main-text-color);
    }

    & > .datalist {
        z-index: 3;
        left: -1px;
        max-height: 300px;
        min-width: calc(100% + 2px);
        position: absolute;
        overflow: auto;
        box-sizing: border-box;
        background-color: var(--input-active-bg, $input-active-bg);
        border-radius: var(--dropdown-rounding, $dropdown-rounding);
        border: 1px solid var(--dropdown-list-border, $dropdown-list-border);
        box-shadow: 0 0 12px -5px #000000bb;
        scrollbar-color: var(--scrollbar-thumb-color, $scrollbar-thumb-color) transparent;
        scrollbar-width: thin;
        transition:
            opacity 0.5s 0.1s,
            max-height 0.3s;
        text-transform: v-bind(textTransform);

        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-thumb {
            -webkit-border-radius: 1ex;
            background-color: var(--scrollbar-thumb-color, $scrollbar-thumb-color);
        }

        &.to-top {
            bottom: 100%;
            margin-bottom: 8px;
        }

        &:not(.to-top) {
            top: 100%;
            margin-top: 2px;
        }

        label {
            display: block;
            cursor: pointer;
            padding: 5px 15px;
            font-weight: 400;
            font-size: 14px;
            text-align: left;
            white-space: nowrap;
            color: var(--label-color, $main-text-color);

            &:first-child {
                padding-top: 10px;
            }

            &:last-child {
                padding-bottom: 10px;
            }

            &.active {
                pointer-events: none;
                color: var(--selected-color, $selected-color);
            }

            &:not(.active):hover {
                color: var(--select-hover-color, $select-hover-color);
            }
        }
    }

    &:not(.focus) {
        height: var(--input-height, $input-height);

        &:not(.filled) {
            & > legend {
                top: 50%;
                transform: translateY(-50%);
                font-size: var(--label-font-size, $label-font-size);
            }
        }

        & > .datalist {
            opacity: 0;
            max-height: 0;
            border-width: 0;
            overflow: hidden;
            transition:
                opacity 0.2s 0.1s,
                max-height 0.3s,
                border-width 0s 0.3s;
        }
    }

    &:not(.focus),
    &:not(.filled) {
        margin-top: 7px;
        height: var(--input-height, $input-height);
    }

    &.focus,
    &.filled {
        margin-top: 0;
        height: calc(var(--input-height, $input-height) + 7px);
        background-color: var(--select-active-bg, $select-active-bg);

        & > legend {
            font-size: 14px;
            height: 14px;
            line-height: 14px;
            position: relative;
        }
    }
}

.disabled {
    opacity: 0.7;
    pointer-events: none;

    legend {
        color: var(--disabled-color, $disabled-color);
    }

    input {
        color: var(--disabled-color, $disabled-color);
    }
}
</style>
