
import { useOrderBookActions } from '@order-book';

const { getTradeResource } = useOrderBookActions();

export default function(stream) {

    const trades = getTradeResource();

    stream.forEach(trade => {
        const idx = trades.state.findIndex(item => item.id === trade.id);
        if (idx > -1) {
            Object.assign(trades.state[idx], trade);
        }
    });
}
