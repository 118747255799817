

import { useChartResources } from '@chart';
import { STATE } from '@/Ship';

export default function (symbol) {
    const [base, counter] = symbol.split('_#_');
    if (STATE.SYMBOL !== `${base}${counter}`) {
        const TradingView = useChartResources('TradingView');
        TradingView.stream.unsubscribe('chart');
        //TradingView.instance.bar = {};
    }
}