<template>
    <button class="g-button"
            :disabled="disabled"
            v-bind:class="[{ square }, { disabled }]">
        <slot/>
    </button>
</template>


<script>
export default {
    name: 'GButton',
    props: {
        square: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
};
</script>

<style lang="scss" scoped>
button {
    border: none;
}

.g-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border-radius: var(--btn-rounding, $btn-rounding);
    height: var(--btn-height, $btn-height);
    font-size: 14px;
    font-weight: 500;
    transition: background-color 0.2s, color 0.2s;

    &:deep(svg) {
        transition: fill 0.2s;
    }

    &.square {
        justify-content: center;
        padding: 0;
    }

    &.disabled,
    &:disabled {
        opacity: 0.5;
        pointer-events: none;
        filter: grayscale(1);
    }
}
</style>