
import { useQuoteResources } from '@quotes';

export default function(reload = false) {
    const stock = useQuoteResources('Stock');
    if (reload || (!stock.isLoaded && !stock.isLoading)) {
        stock.setIncludes(['asset']);
        stock.load();
    }

    return stock;
}
