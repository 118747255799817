<template>
    <div class="wallet-card" v-bind:class="symbol && symbol.toLowerCase()">
        <svg class="logo" height="40" width="40">
            <use xlink:href="@assets/img/logo-full-white.svg#full-logo"/>
        </svg>
        <div class="card-name">{{ network }}</div>
        <div class="balance">
            <div class="balance-title">
                {{ $t('wallet.balance') }}
            </div>
            <div class="balance-value">{{ balance }} {{ symbol }}</div>
        </div>
        <svg class="icon" height="110" width="95">
            <use :xlink:href="require(`@assets/img/currency-card-sprite.svg`) + `#${symbol && symbol.toLowerCase()}`"/>
        </svg>
    </div>
</template>

<script>

import { computed } from 'vue';

export default {
    name: 'WalletCard',
    props: {
        symbol: {
            type: String,
        },
        network: {
            type: String,
        },
        balance: {
            type: Number,
            default: '00.00000000',
        },
    },

    setup(props) {

    }
};
</script>

<style lang="scss" scoped>
.wallet-card {
    border-radius: 18px;
    position: relative;
    padding: 20px;
    background-color: var(--disabled-color, $disabled-color);
    color: var(--payment-card-color, $payment-card-color);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: var(--text-xl, $text-xl);
    aspect-ratio: 15 / 9;
    max-width: 387px;

    .logo {
        top: 22px;
        right: 22px;
        position: absolute;
        width: 40px;
    }

    .card-name {
        font-size: 22px;
        max-width: calc(100% - 64px);
    }

    .balance {
        font-size: 22px;

        &-title {
            opacity: 0.6;
            display: flex;
            align-items: center;
            font-weight: 400;
            font-size: var(--text-xs, $text-xs);
        }

        & svg {
            fill: white;
            margin-left: 6px;
            cursor: pointer;
        }
    }

    &.btc {
        background-color: var(--btc, $btc);
    }

    &.eth {
        background-color: var(--eth, $eth);
    }

    &.trx {
        background-color: var(--trx, $trx);
    }

    &.usdt {
        background-color: var(--usdt, $usdt);
    }

    .icon {
        position: absolute;
        right: 10px;
        bottom: 20px;
    }

    &.disabled {
        filter: grayscale(1);
        pointer-events: none;
        opacity: 0.7;
    }
}
</style>