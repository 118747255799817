
import { useShipActions } from '@/Ship';

const definePriceScale = useShipActions('definePriceScale');

export default function(quote, multiplier = 0) {
    const pricescale = definePriceScale(quote.price);
    const rand = Math.floor((Math.random() * 3) -1) / pricescale;
    quote.price = Number(quote.price) + rand;
    const price = Math.floor((quote.price + (quote.price * multiplier)) * pricescale) / pricescale;

    return {
        time: quote.time,
        volume: quote.volume,
        volume_24: quote.volume_24,
        price: price,
        high: price > quote.high ? price : Math.floor(quote.high * pricescale) / pricescale,
        low: price < quote.low ? price : Math.floor( quote.low  * pricescale) / pricescale,
        open: Math.floor(quote.open * pricescale) / pricescale,
    };
}