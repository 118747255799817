<template>
    <div class="search">
        <g-input v-model="modelValue"
                 v-on:input="$emit('update:modelValue', modelValue)"
                 v-bind:placeholder="$t('base.search', 'Search')">

            <g-symbol v-if="modelValue"
                      name="close"
                      v-on:click="$emit('update:modelValue', '')"
                      width="24"
                      height="24"
                      class="drop-search"/>
            <!--
            <g-symbol v-else
                      name="search"
                      width="24"
                      height="24"/>
             -->
        </g-input>
    </div>
</template>

<script>

export default {
    name: 'Search',
    props: {
        modelValue: {
            type: String
        },
    },
    emits: ['update:modelValue'],
};
</script>
