import axios from 'axios';
import { createI18n } from 'vue-i18n';

const defaultLocale = process.env.VUE_APP_DEFAULT_LOCALE;
const availableLocales = process.env.VUE_APP_AVAILABLE_LOCALES.split('|');
const i18n = createI18n({
    legacy: false,
    locale: defaultLocale,
    fallbackLocale: availableLocales,
    messages: {},
});

i18n.global.loadedLanguages = [defaultLocale];

function setI18nLanguage(locale) {
    if (i18n.mode === 'legacy') {
        i18n.global.locale = locale;
    } else {
        i18n.global.locale.value = locale;
    }
    axios.defaults.headers.common['Accept-Language'] = locale;
    document.querySelector('html').setAttribute('lang', locale);
}

i18n.selectLocale = locale => {
    if (i18n.global.fallbackLocale.value.includes(locale)) {
        setI18nLanguage(locale);
        const dictionary = require(`@/Ship/assets/dictionaries/${locale}.json`);
        i18n.global.setLocaleMessage(locale, dictionary);
        if (!i18n.global.loadedLanguages.includes(locale)) {
            i18n.global.loadedLanguages.push(locale);
        }
    }
};

export default i18n;
