<script setup>

import {computed, ref, defineProps} from 'vue';
import {useQuoteActions} from '@quotes';
import {i18n, PopUpManager as PopUp, useShipActions} from '@/Ship';
import { useProfileActions } from '@profile';

const props = defineProps(['wallet']);

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

const getCryptoResource = useQuoteActions('getCryptoResource');
const crypto = getCryptoResource();

const minUSDTAmount = profile.withdraw_fund ? 200 : 50;

const onlyNumberInput = useShipActions('onlyNumberInput');

const balance = computed(() => {
    return props.wallet.balance - props.wallet.hold_balance;
});

const exchangedValue = computed(() => {
    const currency = props.wallet.coin.symbol;
    const CounterPrice = (currency !== 'USDT')
        ? crypto.find(currency, 'USDT')?.price
        : 1;

    return Math.round(CounterPrice * amount.value);
});

const amount = ref(balance.value);
const error = ref(checkAmount(amount.value));

const amountModel = computed({
    get: () => amount.value,
    set: value => {
        error.value = checkAmount(value);
        amount.value = value;

        return true;
    }
});

function checkAmount(amount) {
    if (amount > balance.value) {
        return 'Amount cannot be greater than balance';
    } else if (amount <= 0) {
        return 'Amount must be greater than zero';
    }

    if (props.wallet.coin.symbol === 'BTC') {
        const CounterPrice = crypto.find('BTC', 'USDT').price;
        const minAmount = Number((minUSDTAmount / CounterPrice).toFixed(4));
        if (amount < minAmount) {
            return `The minimum deposit amount in Bitcoin is ${minAmount} BTC`;
        }
    }

    return null;
}

//const isDisabled = computed(() => {
//    return !(amount.value > 0 && balance.value > 0 && amount.value <= balance.value) || exchangedValue.value < 1000;
//});

const isShowMaxAmount = computed(() => {
    return (balance.value > 0) && amount.value < balance.value;
});

const replenishment = () => {
    PopUp.open(require('@wallet/components/parts/ReplenishingConfirmation'))
        .setCaption(i18n.global.getLocaleMessage(i18n.global.locale.value).popupTitle.replenish)
        .setPayload({wallet: props.wallet, amount: amount.value});
};
</script>

<template>
    <div class="replenishing">
        <g-caption class="title" size="4" weight="700">
            {{ $t('wallet.trade_balance_replenishing') }}
        </g-caption>
        <g-field v-model="amountModel"
                 v-bind:label="'Amount'"
                 v-on:keypress="onlyNumberInput($event)"
                 type="number"
                 v-bind:error="error"
                 required>

            <g-symbol-currency v-bind:name="wallet.coin.symbol.toLowerCase()"
                               width="20"
                               height="20"/>

            <span class="max-btn"
                  v-show="isShowMaxAmount"
                  v-on:click="amount = balance">
                        Max
                    </span>
            <!--<g-tooltip :tooltip-text="$t('label.maxAmount')" hover-tooltip></g-tooltip>-->
        </g-field>

        <g-button v-on:click="replenishment" v-bind:disabled="!!error">
            {{ $t('base.replenishing') }}:
            <strong>{{ exchangedValue }} USDT</strong>
        </g-button>
    </div>
</template>

<style lang="scss" scoped>
.replenishing {
    grid-area: clt;
    max-width: 300px;

    & > .g-caption {
        color: var(--title-text-color, $title-text-color);
    }

    .g-button {
        width: 100%;
        margin-top: 10px;
        background-color: var(--success, $success);
        color: var(--title-text-color, $title-text-color);

        &:hover {
            background-color: var(--success-hover, $success-hover);
        }
    }
}

.max-btn {
    font-size: 13px;
    cursor: pointer;
    margin-right: 12px;
    color: var(--main-text-color, $main-text-color);

    &:hover {
        color: var(--title-text-color, $title-text-color);
    }
}
</style>