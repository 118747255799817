import { ApiClient, STACK, useShipResources } from '@/Ship';

const { BaseResource } = useShipResources();

export default class Wallet extends BaseResource {

    static #instance = null;

    endpoint = `${process.env.VUE_APP_WALLET_SERVER}/client/wallets`;

    section = 'Finances';
    container = 'Wallet';

    static getInstance() {
        if (!Wallet.#instance) {
            Wallet.#instance = new Wallet();
        }
        return Wallet.#instance;
    }

    replenishment(id, params) {
        return STACK.push(() => {
            return ApiClient.post(`${this.endpoint}/replenishment/${id}`, params);
        })
    }
}
