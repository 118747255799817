
import { useProfileResources } from '@profile';

export default (reload = false) => {
    const profile = useProfileResources('Profile');
    if (reload || (!profile.isLoaded && !profile.isLoading)) {
        profile.load();
    }

    return profile;
};
