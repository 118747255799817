<template>
    <span class="g-hint">
        <g-symbol v-if="hintIcon.length>0" :name="`${hintIcon}`" width="14px" height="14px"/>
        <span><slot/></span>
    </span>
</template>

<script>
export default {
    name: 'GHint',
    props: {
        hintIcon: {
            type: String,
            default: ''
        }
    }
};
</script>

<style lang="scss" scoped>
.g-hint {
    position: relative;
    margin-left: 2px;

    svg {
        display: block;
    }

    &:hover {
        & > span {
            display: block;
            z-index: 150;
        }
    }

    & > span {
        position: absolute;
        bottom: 100%;
        margin-bottom: 8px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        background: var(--hint-bg, $field-bg);
        border-radius: 4px;
        color: var(--hint-color, $main-text-color);
        width: 150px;
        padding: 5px 8px;
        display: none;

        &::before {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            left: 50%;
            margin-left: -10px;
            top: 100%;
            border-top: 10px solid var(--hint-bg, $field-bg);
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
        }
    }

    &.to-bottom {
        span {
            top: 100%;
            bottom: auto;
            margin-top: 8px;
            margin-bottom: 0;

            &::before {
                top: auto;
                bottom: 100%;
                border-top: 0;
                border-bottom: 10px solid var(--hint-bg, $field-bg);
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
            }
        }
    }

    &.to-right {
        span {
            left: 100%;
            top: 50%;
            transform: translate(0, -50%);
            bottom: auto;
            margin-left: 8px;
            margin-bottom: 0;

            &::before {
                left: auto;
                right: 100%;
                top: 50%;
                margin-top: -10px;
                border-top: 10px solid transparent;
                border-bottom: 10px solid transparent;
                border-left: 0;
                border-right: 10px solid var(--hint-bg, $field-bg);
            }
        }
    }

    &.to-left {
        span {
            left: auto;
            right: 100%;
            top: 50%;
            transform: translate(0, -50%);
            bottom: auto;
            margin-right: 10px;
            margin-bottom: 0;

            &::before {
                right: auto;
                left: 100%;
                top: 50%;
                margin-top: -10px;
                margin-left: 0;
                border-top: 10px solid transparent;
                border-bottom: 10px solid transparent;
                border-right: 0;
                border-left: 10px solid var(--field-bg, $field-bg);
            }
        }
    }
}
</style>