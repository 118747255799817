import actionLoader from '@/Ship/core/ActionLoader';
import taskLoader from '@/Ship/core/TaskLoader';
import componentLoader from '@/Ship/core/ComponentLoader';

//--- Actions ---//
export function useAuthActions(ask = null) {
    const requireActions = require.context('./actions', true, /[\w-]+\.js$/);

    return ask
        ? actionLoader(requireActions)[ask]
        : actionLoader(requireActions);
}

//--- Tasks ---//
export function useAuthTasks(ask = null) {
    const requireTasks = require.context('./tasks', true, /[\w-]+\.js$/);

    return ask
        ? taskLoader(requireTasks)[ask]
        : taskLoader(requireTasks);
}

export function useAuthenticationTask(ask) {
    const requireComponents = require.context('./tasks', true, /[\w-]+\.js$/);
    return TaskLoader(requireComponents).task(ask);
}

//--- Components ---//
export function useAuthComponents(ask = null) {
    const requireComponents = require.context('./components', true, /[\w-]+\.vue$/);

    return ask
        ? componentLoader(requireComponents)[ask]
        : componentLoader(requireComponents);
}

export default require('./View').default;
