<template>
    <div v-if="!coin.isLoaded">
        <g-preloader is-dark class="g-preloader"/>
    </div>
    <form v-else class="create-wallet" v-on:submit.prevent="createWallet">
        <wallet-card v-bind:wallet-name="payload.name || 'New Wallet'"
                     v-bind:network="coin.item(coinIndex).network"
                     v-bind:symbol="coin.item(coinIndex).symbol"/>
        <g-select v-model="payload.coin_id"
                  v-model:index="coinIndex"
                  v-bind:label="$t('label.currency')"
                  v-bind:options="coin.state"
                  option-text="name"
                  option-value="id"
                  size="large"/>

        <g-select v-model="payload.type"
                  v-bind:label="`${$t('label.internal')}/${$t('label.external')}`"
                  v-bind:options="types"
                  @change="changeType"
                  option-text="label"
                  option-value="value"
                  size="large"/>

        <g-input v-model="payload.address" :disabled="payload.type === 'internal'" v-bind:label="$t('label.external_address')"/>
        <g-input v-model="payload.name" v-bind:label="$t('label.wallet_name')" required/>

        <g-button fill="primary" size="large">
            <g-symbol name="check" width="24" height="24"/>
            {{ $t('button.create') }}
        </g-button>
    </form>
</template>

<script>
import { ref, reactive } from 'vue';
import { useWalletComponents, useWalletActions } from '@wallet';

const {
    getCoinResource,
    getWalletResource
} = useWalletActions();

export default {
    name: 'CreateWallet',
    emits: ['performed'],
    components: {
        WalletCard: useWalletComponents('WalletCard')
    },
    setup(props, {emit}) {

        const coin = getCoinResource();
        const wallet = getWalletResource();

        const coinIndex = ref(0);
        const payload = reactive({
            coin_id: coin.item(coinIndex.value)?.id
        });

        const types = [
            { label: 'Internal', value: 'internal' },
            { label: 'External', value: 'external', },
        ];

        async function createWallet() {
            await wallet.create(payload);
            emit('performed');
        }

        function changeType(){
            payload.address = '';
        }

        return {
            coin,
            coinIndex,
            types,
            payload,
            createWallet,
            changeType
        };
    }
};
</script>

<style scoped lang="scss">
form {
    &:deep(.g-button),
    &:deep(.g-select),
    &:deep(.g-input) {
        width: 100%;
        margin: 16px 0;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.wallet-card {
    margin: 48px auto 50px;
    max-width: 317px;
}

</style>
