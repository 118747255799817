<script setup>

import { onMounted, ref, computed } from 'vue';
import { STACK, ApiClient } from '@/Ship';
import { useProfileActions } from '@profile';

const getProfileResource = useProfileActions('getProfileResource');
const  uploadDocument = useProfileActions('uploadDocument');

const profile = getProfileResource();

let documents = ref([
    {
        url: null,
        key: 'id_front',
        name: 'ID Card (front)'
    },
    {
        url: null,
        key: 'id_back',
        name: 'ID Card (back)'
    },
    {
        url: null,
        key: 'card_front',
        name: 'Card (front)'
    },
    {
        url: null,
        key: 'card_back',
        name: 'Card (back)'
    },
    {
        url: null,
        key: 'dod',
        name: 'DOD'
    },
    {
        url: null,
        key: 'ub',
        name: 'UB'
    },
]);

const host = computed(() => {
    return process.env.VUE_APP_SERVER;
});

onMounted(() => {
    for (const key in profile.documents) {
        const index = documents.value.findIndex(doc => doc.key === key);
        if (index > -1) {
            documents.value[index].url = profile.documents[key]?.url;
        }
    }
});

const onFileChange = (e, target) => {
    let files = e.target.files || e.dataTransfer.files;
    return !files.length
        ? null
        : createImage(files[0], target);
};

const url2File = (url, filename, mimeType) => {
    return (fetch(url)
            .then(function (res) {return res.arrayBuffer();})
            .then(function (buf) {return new File([buf], `${filename}.jpeg`, { type: mimeType });})
    );
};

const createImage = (file, target) => {
    let img = new Image();
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = event => {
        img.src = event.target.result;

        img.onload = function () {

            let canvas = document.createElement('canvas');
            let ctx = canvas.getContext('2d');

            canvas.width = (img.width > img.height)
                ? 800
                : 500;
            canvas.height = img.height * (canvas.width / img.width);

            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, canvas.width, canvas.height);

            url2File(canvas.toDataURL('image/jpeg'), target, 'image/jpeg').then((encodedFile) => {
                uploadDocument(target, encodedFile).then((response) => {
                    const index = documents.value.findIndex(doc => doc.key === target);
                    documents.value[index].url = response.url;
                });
            });
        };
    };
};

function removeDocument(index) {
    STACK.push(() => {
        return ApiClient.delete(`profile/document/${documents.value[index].key}`);
    }).then(() => {
        documents.value[index].url = null;
    });
}
</script>

<template>
    <div class="documents">
        <div v-for="(item, i) in documents"
             v-bind:key="item.key"
             class="item">
            <template v-if="!!item.url">
                <img v-bind:src="`${host}${item.url}`" v-bind:alt="item.name"/>
                <span class="remove-btn" v-on:click.stop="removeDocument(i)">✕</span>
            </template>
            <label v-else>
                <g-symbol name="plus" width="24" height="24"/>
                <br>
                {{ $t('base.import', 'Import') }}
                <br>
                <b>{{ item.name }}</b>
                <input type="file"
                       v-on:change="onFileChange($event, item.key)"
                       accept="image/jpeg, image/png, image/webp"
                       hidden/>
            </label>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.documents {
    flex-grow: 0;
    display: grid;
    grid-gap: 20px;
    height: min-content;

    & > .item {
        position: relative;
        aspect-ratio: 16/9;
        background-color: var(--env-bg, $env-bg);

        & > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: var(--bar-rounding, $bar-rounding);
        }

        & > label {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            cursor: pointer;
            border: 1px solid var(--separator-color, $separator-color);
            border-radius: var(--bar-rounding, $bar-rounding);

            & > svg {
                fill: var(--main-text-color, $main-text-color);
            }

            & > b {
                font-size: 16px;
                color: var(--primary, $primary);
            }
        }

        & > .remove-btn {
            border-radius: 50%;
            text-align: center;
            cursor: pointer;
            z-index: 10;
            top: 2px;
            right: 2px;
            width: 24px;
            height: 24px;
            line-height: 24px;
            position: absolute;
            background-color: white;
        }

        &:hover {
            svg {
                fill: var(--primary-hover, $primary-hover);
            }
        }
    }
}

@media (min-width: 1825px) {
    .documents {
        grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    }
}

@media (max-width: 1824px) {
    .documents {
        grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    }
}
</style>
