<template>
    <div class="g-input" :class="[{'error': error}, { disabled: disabled}, {filled: !!modelValue}]">
        <input v-bind:value="modelValue"
               v-on:input="inputEvent"
               v-bind="$attrs"
               :disabled="disabled">
        <slot/>
        <div v-if="hint" class="hint-text">{{ hint }}</div>
    </div>
    <div v-if="clue" class="clue-text">{{ clue }}</div>
</template>

<script>
export default {
    name: 'GInput',
    inheritAttrs: false,
    props: {
        stroke: {
            type: String
        },
        modelValue: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        hint: { type: String },
        clue: { type: String },
        error: {
            type: Boolean,
            default: false
        },
    },
    emits: ['update:modelValue'],

    setup(props, { emit }) {
        const inputEvent = event => {
            emit('update:modelValue', event.target.value);
        };

        return {
            inputEvent
        };
    }
};
</script>

<style lang="scss" scoped>
.g-input {
    padding: 0;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    position: relative;
    height: var(--input-height, $input-height);
    color: var(--main-text-color, $main-text-color);
    background-color: var(--input-bg, $input-bg);
    border-radius: var(--input-rounding, $input-rounding);
    border: 1px solid var(--input-border, $input-border);
    transition: background-color 0.2s;

    input {
        width: 100%;
        height: 100%;
        flex-grow: 1;
        border: none;
        padding-left: 10px;
        background-color: transparent;
        color: var(--title-text-color, $title-text-color);
        font-family: var(--font-primary, $font-primary);
        font-size: var(--input-font-size, $input-font-size);

        &:focus {
            outline: none;
        }
    }

    &:deep(svg) {
        cursor: pointer;
        margin-right: 12px;
        fill: var(--main-text-color, $main-text-color);

        &:hover {
            fill: var(--title-text-color, $title-text-color);
        }
    }

    &.filled,
    &:focus-within {
        background-color: var(--input-active-bg, $input-active-bg);

        & > input {
            border-radius: var(--input-active-bg, $input-rounding);

            &:-webkit-autofill {
                background: transparent;
                transition: background-color 5000s ease-in-out 0s;
                -webkit-text-fill-color: var(--main-text-color, $main-text-color);
            }
        }

        &:deep(svg) {
            fill: var(--title-text-color, $title-text-color);
        }
    }

    &.error {
        border-color: var(--danger, $danger);

        input {
            background: var(--input-active-bg, $input-active-bg);
        }

        .hint-text {
            color: var(--danger, $danger);
        }
    }

    &.disabled {
        opacity: 0.7;
        pointer-events: none;

        input {
            color: var(--disabled-color, $disabled-color);
        }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type='search']::-webkit-search-decoration,
    input[type='search']::-webkit-search-cancel-button,
    input[type='search']::-webkit-search-results-button,
    input[type='search']::-webkit-search-results-decoration {
        -webkit-appearance: none;
    }

    input[type='number'] {
        -moz-appearance: textfield;
    }
}

.clue-text {
    font-size: 12px;
    position: relative;
    top: -10px;
}

.hint-text {
    bottom: 100%;
    left: 50%;
    max-width: 100%;
    padding: 5px 15px;
    border-radius: 3px;
    position: absolute;
    transform: translateX(-50%);
    background-color: var(--hint-bg, $hint-bg);
    color: var(--hint-text-color, $hint-text-color);

    &::after {
        content: '';
        bottom: -10px;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        border: 5px solid transparent;
        border-top-color: var(--hint-bg, $hint-bg);
    }
}
</style>
