import { i18n, ValidRules } from '@/Ship';
const validRules = new ValidRules();

const verifyField = (value, rules) => {
    const errors = [];
    for (let i = 0; i < rules.length; i++) {
        let rule = rules[i].replace(/\s/g, '');
        let isRuleHasParam = rule.indexOf(':') > -1;
        let param = isRuleHasParam
            ? rule.split(':')[1]
            : undefined;
        rule = isRuleHasParam
            ? rule.split(':')[0]
            : rule;

        if (!validRules[rule](value, param)) {
            errors.push(i18n.global.t(`validator.${rule}`, { param }));
        }
    }


    return errors.length
        ? errors.join('. ')
        : null;
};

export default verifyField;